import React from "react";
import { QuestionOption } from "../../../shared/types/types";
import { Box, Typography } from "@mui/material";

type Props = {
  option: QuestionOption;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
  maxSelections: number;
};

const MCQuestionOption = ({ option, selectedOptions, setSelectedOptions, maxSelections }: Props) => {
  const isSelected = selectedOptions.includes(option.optionId);
  const optionIndex = selectedOptions.indexOf(option.optionId);
  const optionNumber = isSelected ? optionIndex + 1 : null;

  const handleClick = () => {
    setSelectedOptions((prev) => {
      if (maxSelections === 1) {
        // Only one selection is allowed.
        if (prev.includes(option.optionId)) {
          // Toggle off if already selected.
          return [];
        } else {
          // Replace any existing selection.
          return [option.optionId];
        }
      } else {
        // When two selections are allowed, use the original logic.
        if (optionIndex === -1) {
          // If not selected, add if there is room.
          return prev.length < 2 ? [...prev, option.optionId] : prev;
        } else if (optionIndex === 1) {
          // If this option is currently the second selection, remove it.
          return prev.slice(0, 1);
        } else if (optionIndex === 0) {
          // If this option is the first selection, remove it and shift the second (if present).
          return prev.length === 2 ? [prev[1]] : [];
        }
        return prev;
      }
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2, cursor: "pointer" }} onClick={handleClick}>
      <Box
        sx={{
          width: 32,
          height: 32,
          minWidth: 32,
          minHeight: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 1,
          border: "1px solid #616A66",
          bgcolor: isSelected ? "#E5F7F4" : "white",
          fontSize: "16px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        {optionNumber}
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: isSelected ? "#171918" : "#616A66",
          fontWeight: isSelected ? 700 : 400,
        }}
      >
        {option.optionText}
      </Typography>
    </Box>
  );
};

export default MCQuestionOption;
