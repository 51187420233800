import { BrowserRouter as Router } from "react-router-dom";
import { SiteRoutes } from "./shared/routes/Routes";
import ErrorSnackbar from "./shared/components/errorHandling/ErrorSnackbar";
import { useRecoilValue } from "recoil";
import { pageLoadingAtom } from "./shared/recoil/loadingAtoms";
import SiteLoadingPage from "./shared/pages/SiteLoadingPage";
import { StudentDBProvider } from "./students/providers/StudentDBProvider";
import { Box } from "@mui/material";
import StudentSideDesktopNavbar from "./students/components/navigation/StudentSideDesktopNavbar";
import useWindowDimensions from "./shared/hooks/responsiveLayout/useWindowDimensions";
import StudentBottomMobileNavbar from "./students/components/navigation/StudentBottomMobileNavbar";
import { loggedInStudentAtom } from "./shared/recoil/userAtoms";
import useTrackUserActivity from "./students/hooks/lastActivityLogger/useLastActivityLogger";
import { GetStartedModal } from "./students/components/navigation/GetStartedModal";
import { useMemo } from "react";

const StudentBootstrap = () => {
  console.log("STUDENT BOOTSTRAP RENDERED");
  const loading = useRecoilValue(pageLoadingAtom);
  const { width } = useWindowDimensions();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  useTrackUserActivity();

  const mobile = width < 900;

  const setup = useMemo(
    () => ({
      Complete: loggedInStudent?.setupComplete,
      getStartedComplete: loggedInStudent?.getStartedData?.getStartedComplete,
    }),
    [loggedInStudent],
  );
  console.log("Page Loading", loading, "Logged In Student", loggedInStudent);

  return (
    <>
      <StudentDBProvider>
        <Box sx={{ display: "flex", backgroundColor: "#fafafa" }}>
          {!loading ? (
            <Router>
              {!mobile && setup.Complete && (
                <Box
                  sx={{
                    width: "210px", // Fixes the width
                    flexShrink: 0, // Prevents shrinking
                  }}
                >
                  <StudentSideDesktopNavbar />
                </Box>
              )}
              <Box
                sx={{
                  flexGrow: 1, // Ensures it takes up the remaining width
                  width: "100%", // Spans the full width
                  minHeight: "100vh", // Ensures it spans the full height
                  display: "flex", // Maintains flexibility
                  flexDirection: "column", // Optional, depending on the layout
                }}
              >
                <SiteRoutes />
                {mobile && setup.Complete && <StudentBottomMobileNavbar />}
              </Box>
              {!mobile && setup.Complete && !setup.getStartedComplete && (
                <Box sx={{ position: "absolute", bottom: 12, right: 12, zIndex: 1200 }}>
                  <GetStartedModal />
                </Box>
              )}
            </Router>
          ) : (
            <Box component="main" sx={{ flexGrow: 1 }}>
              <SiteLoadingPage />
            </Box>
          )}
          <ErrorSnackbar />
        </Box>
      </StudentDBProvider>
    </>
  );
};

export default StudentBootstrap;
