import { UserType } from "../types/enums";
import {
  ProviderUserRecord,
  StaffRecord,
  StudentRecord,
  MentorUserRecord,
  SchoolRecord,
  DistrictRecord,
  EFCDataRecord,
  QuestionRecord,
  AnswerRecord,
  JobDetailsRecord,
  WageDataRecord,
  EducationAttainmentRecord,
  PersonalityTypeRecord,
  VideoInteractionRecord,
  MSACodeRecord,
  StudentGroup,
  GoalRecord,
  MilestoneRecord,
} from "../types/types";

export const parseStaffResponse = (response: StaffRecord[]): StaffRecord[] =>
  response.map((staff: StaffRecord) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    createdAt: staff?.createdAt ?? null,
    lastUpdatedAt: staff?.lastUpdatedAt ?? null,
    schoolId: staff?.schoolId ?? null,
    avatar: staff?.avatar ?? "",
    jobTitle: staff?.jobTitle ?? "",
    userType: staff?.userType ?? UserType.STUDENT,
    districtId: staff?.districtId ?? null,
    enrolled: staff?.enrolled ?? true,
    initialPasswordReset: staff?.initialPasswordReset ?? false,
    smsNumber: staff?.smsNumber ?? "",
    klaviyoProfileId: staff?.klaviyoProfileId ?? "",
    uidId: staff?.uidId ?? false,
    oldId: staff?.oldId ?? "",
  }));

export const parseSingleStaffResponse = (staff: StaffRecord): StaffRecord => ({
  id: staff?.id ?? "",
  firstName: staff?.firstName ?? "",
  lastName: staff?.lastName ?? "",
  email: staff?.email ?? "",
  createdAt: staff?.createdAt ?? null,
  lastUpdatedAt: staff?.lastUpdatedAt ?? null,
  schoolId: staff?.schoolId ?? null,
  avatar: staff?.avatar ?? "",
  jobTitle: staff?.jobTitle ?? "",
  userType: staff?.userType ?? UserType.STUDENT,
  districtId: staff?.districtId ?? null,
  enrolled: staff?.enrolled ?? true,
  initialPasswordReset: staff?.initialPasswordReset ?? false,
  smsNumber: staff?.smsNumber ?? "",
  klaviyoProfileId: staff?.klaviyoProfileId ?? "",
  uidId: staff?.uidId ?? false,
  oldId: staff?.oldId ?? "",
});

export const parseMentorUsersResponse = (response: MentorUserRecord[]): MentorUserRecord[] =>
  response.map((response: MentorUserRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    firstName: response?.firstName ?? "",
    lastName: response?.lastName ?? "",
    avatar: response?.avatar ?? "",
    avatarURL: response?.avatarURL ?? "",
    userType: response?.userType ?? UserType.MENTOR,
    email: response?.email ?? "",
    klaviyoProfileId: response?.klaviyoProfileId ?? "",
    studentIds: response?.studentIds ?? [],
  }));

export const parseStudentGroupsResponse = (response: StudentGroup[]): StudentGroup[] =>
  response.map((response: StudentGroup) => ({
    id: response?.id ?? "",
    groupName: response?.groupName ?? "",
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
    createdByStaffId: response?.createdByStaffId ?? "",
    createdAt: response?.createdAt,
    lastUpdatedAt: response?.lastUpdatedAt,
  }));

const parseStudentData = (student: StudentRecord): StudentRecord => ({
  id: student?.id ?? "",
  firstName: student?.firstName ?? "",
  lastName: student?.lastName ?? "",
  createdAt: student?.createdAt ?? null,
  lastUpdatedAt: student?.lastUpdatedAt ?? null,
  schoolId: student?.schoolId ?? null,
  externalId: student?.externalId ?? null,
  schoolGroups: student?.schoolGroups ?? [],
  districtId: student?.districtId ?? null,
  avatar: student?.avatar ?? "",
  gradeLevel: student?.gradeLevel ?? "",
  userType: student?.userType ?? UserType.STUDENT,
  birthday: student?.birthday ?? null,
  graduationYear: student?.graduationYear ?? "",
  phone: student?.phone ?? "",
  lastQuestionId: student?.lastQuestionId ?? null,
  quizComplete: student?.quizComplete ?? false,
  setupComplete: student?.setupComplete ?? false,
  lastActivity: student?.lastActivity ?? "",
  optInTextMessages: student?.optInTextMessages ?? false,
  agreeTermsOfService: student?.agreeTermsOfService ?? false,
  enrolled: student?.enrolled ?? true,
  personalEmail: student?.personalEmail ?? "",
  schoolEmail: student?.schoolEmail ?? "",
  email: student?.email ?? "",
  consentToContact: student?.consentToContact ?? "Not Asked",
  staffRecommendedProgramIds: student?.staffRecommendedProgramIds ?? [],
  willowRecommendedProgramIds: student?.willowRecommendedProgramIds ?? [],
  favoriteProgramIds: student?.favoriteProgramIds ?? [],
  removedProgramIds: student?.removedProgramIds ?? [],
  klaviyoProfileId: student?.klaviyoProfileId ?? "",
  mentorIds: student?.mentorIds ?? [],
  completedTours: student?.completedTours ?? [],
  gpaValue: student?.gpaValue ?? null,
  gpaMax: student?.gpaMax ?? null,
  act: student?.act ?? "",
  sat: student?.sat ?? "",
  frlStatus: student?.frlStatus ?? "",
  myWhy: student?.myWhy ?? "",
  whatDescribesMe: student?.whatDescribesMe ?? "",
  address: {
    address: student?.address?.address ?? "",
    lat: student?.address?.lat ?? 0,
    lon: student?.address?.lon ?? 0,
    city: student?.address?.city ?? "",
    state: student?.address?.state ?? "",
    county: student?.address?.county ?? "",
    zip: student?.address?.zip ?? "",
  },
  efc: student?.efc ?? null,
  preQuizComplete: student?.preQuizComplete ?? false,
  personalityType: student?.personalityType ?? "",
  hasViewedPersonalityType: student?.hasViewedPersonalityType ?? false,
  dislikedJobIds: student?.dislikedJobIds ?? [],
  favoriteJobIds: student?.favoriteJobIds ?? [],
  includeOnlineOnly: student?.includeOnlineOnly ?? true,
  citiesForRecommendations: student?.citiesForRecommendations ?? [],
  statesForRecommendations: student?.statesForRecommendations ?? [],
  programTypeForRecommendations: student?.programTypeForRecommendations ?? "",
  willowRecommendedJobIds: student?.willowRecommendedJobIds ?? [],
  staffRecommendedJobIds: student?.staffRecommendedJobIds ?? [],
  bookmarkedACareer: student?.bookmarkedACareer ?? false,
  hideFeedMessage: student?.hideFeedMessage ?? false,
  firstGen: student?.firstGen ?? false,
  getStartedData: student.getStartedData ?? {
    finishedPersonalitySuccess: false,
    careersBookmarkSuccess: false,
    programsBookmarkSuccess: false,
    getStartedComplete: false,
  },
  onboardingStage: student?.onboardingStage ?? 2,
  personalFeedback: student?.personalFeedback ?? [],
  intakeComplete: student?.intakeComplete ?? false,
  pressingChallengesComplete: student?.pressingChallengesComplete ?? false,
  pressingChallengeScores: student?.pressingChallengeScores ?? [],
});

export const parseStudentResponse = (response: StudentRecord[]): StudentRecord[] => response.map(parseStudentData);

export const parseSingleStudentResponse = (response: StudentRecord): StudentRecord => parseStudentData(response);

export const parseSingleProviderUserResponse = (response: ProviderUserRecord): ProviderUserRecord => ({
  id: response?.id ?? "",
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  userType: response?.userType ?? UserType.STUDENT,
  setupComplete: response?.setupComplete ?? false,
  email: response?.email ?? "",
  klaviyoProfileId: response?.klaviyoProfileId ?? "",
  providerId: response?.providerId ?? "",
  phone: response?.phone ?? "",
});

export const parseSingleMentorUserResponse = (response: MentorUserRecord): MentorUserRecord => ({
  id: response?.id ?? "",
  firstName: response?.firstName ?? "",
  lastName: response?.lastName ?? "",
  avatar: response?.avatar ?? "",
  avatarURL: response?.avatarURL ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  userType: response?.userType ?? UserType.STUDENT,
  email: response?.email ?? "",
  klaviyoProfileId: response?.klaviyoProfileId ?? "",
  studentIds: response?.studentIds ?? [],
});

export const parseDistrictResponse = (response: DistrictRecord[]): DistrictRecord[] =>
  response.map((response: DistrictRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? null,
    domains: response?.domains ?? [],
  }));

export const parseSchoolResponse = (response: SchoolRecord[]): SchoolRecord[] =>
  response.map((response: SchoolRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    name: response?.name ?? null,
    historicId: response?.historicId ?? null,
    primaryEmail: response?.primaryEmail ?? "",
    primaryPhone: response?.primaryPhone ?? "",
    avatar: response?.avatar ?? "",
    primaryContact: response?.primaryContact ?? "",
    districtId: response?.districtId ?? null,
  }));

export const parseSingleEFCDataResponse = (response: EFCDataRecord): EFCDataRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  studentIncome: response?.studentIncome ?? 0,
  studentAssets: response?.studentAssets ?? 0,
  ageOfOldestParent: response?.ageOfOldestParent ?? 0,
  parentIncome: response?.parentIncome ?? 0,
  householdSize: response?.householdSize ?? 0,
  childrenInCollege: response?.childrenInCollege ?? 0,
  parentUntaxedIncome: response?.parentUntaxedIncome ?? 0,
  maritalStatus: response?.maritalStatus ?? "single",
  parentAssets: response?.parentAssets ?? 0,
  state: response?.state ?? "",
  childrenUnder18: response?.childrenUnder18 ?? 0,
});

export const parseQuestionsResponse = (response: QuestionRecord[]): QuestionRecord[] =>
  response.map((response: QuestionRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    questionText: response?.questionText ?? "",
    options: response?.options ?? [],
    order: response?.order ?? 0,
    questionType: response?.questionType ?? "ms",
    helperText: response?.helperText ?? "",
  }));

export const parseAnswersResponse = (response: AnswerRecord[]): AnswerRecord[] =>
  response.map((response: AnswerRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    questionId: response?.questionId ?? "",
    districtId: response?.districtId ?? "",
    schoolId: response?.schoolId ?? "",
    answerChoices: response?.answerChoices ?? [],
  }));

export const parseSingleJobResponse = (response: JobDetailsRecord): JobDetailsRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  title: response?.title ?? "",
  description: response?.description ?? "",
  industry_id: response?.industry_id ?? 0,
  industry_sub_id: response?.industry_sub_id ?? 0,
  bright_outlook: response?.bright_outlook ?? false,
  green_occupation: response?.green_occupation ?? false,
  cip_codes: response?.cip_codes ?? [],
  projectedGrowth: response?.projectedGrowth ?? null,
  projectedOpenings: response?.projectedOpenings ?? null,
  startingHourly: response?.startingHourly ?? null,
  startingSalary: response?.startingSalary ?? null,
  medianSalary: response?.medianSalary ?? null,
  medianHourly: response?.medianHourly ?? null,
  zone: response?.zone ?? null,
  competition: response?.competition ?? null,
});

export const parseJobsResponse = (response: JobDetailsRecord[]): JobDetailsRecord[] =>
  response.map((response: JobDetailsRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    title: response?.title ?? "",
    description: response?.description ?? "",
    industry_id: response?.industry_id ?? 0,
    industry_sub_id: response?.industry_sub_id ?? 0,
    bright_outlook: response?.bright_outlook ?? false,
    green_occupation: response?.green_occupation ?? false,
    cip_codes: response?.cip_codes ?? [],
    projectedGrowth: response?.projectedGrowth ?? null,
    projectedOpenings: response?.projectedOpenings ?? null,
    startingHourly: response?.startingHourly ?? null,
    startingSalary: response?.startingSalary ?? null,
    medianSalary: response?.medianSalary ?? null,
    medianHourly: response?.medianHourly ?? null,
    zone: response?.zone ?? null,
    competition: response?.competition ?? null,
  }));

export const parseSingeWageDataResponse = (response: WageDataRecord): WageDataRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  OCC_CODE: response?.OCC_CODE ?? "",
  H_PCT10: response?.H_PCT10 ?? "",
  H_PCT25: response?.H_PCT25 ?? "",
  H_MEDIAN: response?.H_MEDIAN ?? "",
  H_PCT75: response?.H_PCT75 ?? "",
  H_PCT90: response?.H_PCT90 ?? "",
  A_PCT10: response?.A_PCT10 ?? "",
  A_PCT25: response?.A_PCT25 ?? "",
  A_MEDIAN: response?.A_MEDIAN ?? "",
  A_PCT75: response?.A_PCT75 ?? "",
  A_PCT90: response?.A_PCT90 ?? "",
  PRIM_STATE: response?.PRIM_STATE ?? "",
  I_GROUP: response?.I_GROUP ?? "",
});

export const parseSingeEducationAttainmentResponse = (
  response: EducationAttainmentRecord,
): EducationAttainmentRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  OCC: response?.OCC ?? "",
  associates: response?.associates ?? 0,
  bachelors: response?.bachelors ?? 0,
  highSchool: response?.highSchool ?? 0,
  masters: response?.masters ?? 0,
  phd: response?.phd ?? 0,
  title: response?.title ?? "",
});

export const parseSinglePersonalityTypeRecord = (response: PersonalityTypeRecord): PersonalityTypeRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  title: response?.title ?? "",
  shortDescription: response?.shortDescription ?? "",
  whatItMeans: response?.whatItMeans ?? "",
  superpowers: response?.superpowers ?? "",
  areasToImprove: response?.areasToImprove ?? "",
  howYouCommunicate: response?.howYouCommunicate ?? "",
  workStyle: response?.workStyle ?? "",
  relationshipTips: response?.relationshipTips ?? "",
  recommendedCareersOpening: response?.recommendedCareersOpening ?? "",
  recommendedCareers: response?.recommendedCareers ?? [],
  possibleMajors: response?.possibleMajors ?? [],
  possibleProfessionalPrograms: response?.possibleProfessionalPrograms ?? [],
  personalGoals: response?.personalGoals ?? [],
  studyTips: response?.studyTips ?? [],
  inspirationalQuotes: response?.inspirationalQuotes ?? [],
  closing: response?.closing ?? "",
});

export const parseVideoInteractionResponse = (response: VideoInteractionRecord[]): VideoInteractionRecord[] =>
  response.map((response: VideoInteractionRecord) => ({
    id: response?.id ?? "",
    createdAt: response?.createdAt ?? null,
    lastUpdatedAt: response?.lastUpdatedAt ?? null,
    studentId: response?.studentId ?? "",
    videoId: response?.videoId ?? "",
    watched: response?.watched ?? false,
    schoolId: response?.schoolId ?? "",
    districtId: response?.districtId ?? "",
    interaction: response?.interaction ?? "",
    timestamp: response?.timestamp ?? "",
    onetCode: response?.onetCode ?? "",
    clickedLearnMore: response?.clickedLearnMore ?? false,
  }));

export const parseSingleMSACodeResponse = (response: MSACodeRecord): MSACodeRecord => ({
  id: response?.id ?? "",
  createdAt: response?.createdAt ?? null,
  lastUpdatedAt: response?.lastUpdatedAt ?? null,
  COUNTY_NAME: response?.COUNTY_NAME ?? "",
  MSA: response?.MSA ?? "",
  STATE_NAME: response?.STATE_NAME ?? "",
});

export const parseGoalResponse = (response: GoalRecord[]): GoalRecord[] =>
  response.map((goal: GoalRecord) => ({
    studentId: goal?.studentId ?? "",
    goal: goal?.goal ?? "",
    state: goal?.state ?? "active",
    id: goal?.id ?? "",
    createdAt: goal?.createdAt ?? null,
    lastUpdatedAt: goal?.lastUpdatedAt ?? null,
    title: goal?.title ?? "",
  }));

export const parseMilestoneResponse = (response: MilestoneRecord[]): MilestoneRecord[] =>
  response.map((milestone: MilestoneRecord) => ({
    goalId: milestone?.goalId ?? "",
    milestone: milestone?.milestone ?? "",
    completed: milestone?.completed ?? false,
    id: milestone?.id ?? "",
    createdAt: milestone?.createdAt ?? null,
    lastUpdatedAt: milestone?.lastUpdatedAt ?? null,
    dueDate: milestone?.dueDate ?? null,
    studentId: milestone?.studentId ?? "",
  }));
