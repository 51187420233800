import React, { SetStateAction, useEffect, useState } from "react";
import PersonalityQuizLayout from "./PersonalityQuizLayout";
import { Box, CircularProgress } from "@mui/material";
import QuestionCard from "./QuestionCard";
import QuizProgressBar from "./QuizProgressBar";
import { QuestionRecord, AnswerRecord } from "../../../shared/types/types";
import { fetchAPI } from "../../../shared/utils/fetchUtils";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilState } from "recoil";
import useDetermineQuizResults from "../../hooks/quiz/useDetermineQuizResults";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "../../../shared/types/enums";

/* --- Custom hooks for data fetching --- */
const useQuestions = (setLoading: React.Dispatch<SetStateAction<boolean>>) => {
  const [questions, setQuestions] = useState<QuestionRecord[]>([]);

  useEffect(() => {
    const getQuestions = async () => {
      try {
        console.log("GETTING QUESTIONS");
        setLoading(true);
        const response = await fetchAPI({
          functionName: "quizAPI",
          path: "getAllQuestions",
          method: "GET",
          payload: {},
        });
        const data = await response.json();
        setQuestions(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching questions", error);
      }
    };
    getQuestions();
  }, [setLoading]);
  return questions;
};

const useStudentAnswers = (studentId?: string) => {
  const [studentAnswers, setStudentAnswers] = useState<AnswerRecord[]>([]);
  useEffect(() => {
    const getAnswers = async () => {
      if (!studentId) return;
      console.log("GETTING STUDENT ANSWERS");
      try {
        const response = await fetchAPI({
          functionName: "quizAPI",
          path: "getAllStudentAnswers",
          method: "GET",
          payload: { studentId },
        });
        if (response.ok) {
          const data = await response.json();
          setStudentAnswers(data);
        } else {
          console.error("Failed to fetch student answers", await response.text());
        }
      } catch (error) {
        console.error("Error fetching student answers", error);
      }
    };
    getAnswers();
  }, [studentId]);
  return [studentAnswers, setStudentAnswers] as const;
};

/* --- Main component --- */
const PersonalityQuizTake: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const questions = useQuestions(setLoading);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [studentAnswers, setStudentAnswers] = useStudentAnswers(loggedInStudent?.id);
  const [initialIndexSet, setInitialIndexSet] = useState(false);
  const { determineQuizResults } = useDetermineQuizResults();
  const navigate = useNavigate();

  // Set the initial question index based on last answered question.
  useEffect(() => {
    if (!initialIndexSet && questions.length > 0 && loggedInStudent?.lastQuestionId) {
      const lastQuestionIndex = questions.findIndex((q) => q.id === loggedInStudent.lastQuestionId);
      if (lastQuestionIndex !== -1 && lastQuestionIndex + 1 < questions.length) {
        setCurrentQuestionIndex(lastQuestionIndex + 1);
      }
      if (lastQuestionIndex === questions.length - 1) {
        navigate(PageRoute.PERSONALITY_QUIZ_PREVIEW);
      }
      setInitialIndexSet(true);
    }
  }, [questions, loggedInStudent, initialIndexSet, navigate]);

  const currentQuestion = questions[currentQuestionIndex] || null;
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  // Prepopulate selected options when the current question changes.
  useEffect(() => {
    if (currentQuestion && studentAnswers.length > 0) {
      const foundAnswer = studentAnswers.find((answer) => answer.questionId === currentQuestion.id);
      if (foundAnswer) {
        const sortedChoices = [...foundAnswer.answerChoices].sort((a, b) => a.choice - b.choice);
        setSelectedOptions(sortedChoices.map((choice) => choice.optionId));
      } else {
        setSelectedOptions([]);
      }
    }
  }, [currentQuestion, studentAnswers]);

  // Helper: Build the payload for answer submission.
  const buildAnswerPayload = () => {
    if (!currentQuestion || !loggedInStudent) return null;
    const answerChoices = selectedOptions.map((optionId, index) => {
      const option = currentQuestion.options.find((opt) => opt.optionId === optionId);
      return {
        optionId,
        optionAlignment: option ? option.optionAlignment : null,
        choice: index + 1,
      };
    });
    return {
      questionId: currentQuestion.id,
      answerChoices,
      districtId: loggedInStudent.districtId,
      schoolId: loggedInStudent.schoolId,
      studentId: loggedInStudent.id,
    };
  };

  // Handler for advancing to the next question.
  const handleNext = async () => {
    if (!currentQuestion || !loggedInStudent) return;
    if (currentQuestion.options.length > 2 && selectedOptions.length < 2) {
      alert("Please select your top two options");
      return;
    } else if (selectedOptions.length < 1) {
      alert("Please select an option");
      return;
    }
    const answerPayload = buildAnswerPayload();
    if (!answerPayload) return;

    const highestAnsweredIndex = loggedInStudent.lastQuestionId
      ? questions.findIndex((q) => q.id === loggedInStudent.lastQuestionId)
      : -1;

    // Optimistically move to the next question (if not at the end).
    if (!isLastQuestion) {
      setCurrentQuestionIndex((prev) => prev + 1);
      setSelectedOptions([]);
    }

    try {
      // Submit the answer.
      const response = await fetchAPI({
        functionName: "quizAPI",
        path: "submitAnswer",
        method: "POST",
        payload: answerPayload,
      });
      if (!response.ok) {
        console.error("Failed to submit answer", await response.text());
        return;
      }
      const result: AnswerRecord = await response.json();
      console.log("Answer submitted:", result);

      // Update local student answers.
      setStudentAnswers((prevAnswers) => {
        const filtered = prevAnswers.filter((ans) => ans.questionId !== currentQuestion.id);
        return [...filtered, result];
      });

      // Determine the new last question ID.
      const newLastQuestionId =
        currentQuestionIndex > highestAnsweredIndex ? currentQuestion.id : loggedInStudent.lastQuestionId;

      // Build payload for updating student record.
      const updatePayload: any = {
        studentId: loggedInStudent.id,
        updates: { lastQuestionId: newLastQuestionId },
      };

      // If it's the last question, determine and include the personality type.
      if (isLastQuestion) {
        // Merge the new answer with existing ones for an up-to-date calculation.
        const updatedAnswers = [...studentAnswers.filter((ans) => ans.questionId !== currentQuestion.id), result];
        const personalityTypeName = determineQuizResults(updatedAnswers);
        updatePayload.updates.personalityType = personalityTypeName;
      }

      // Update the student's record.
      const updateResponse = await fetchAPI({
        functionName: "studentsAPI",
        path: "updateStudent",
        method: "POST",
        payload: updatePayload,
      });
      if (!updateResponse.ok) {
        console.error("Failed to update student", await updateResponse.text());
      } else {
        const updatedStudent = await updateResponse.json();
        setLoggedInStudent((prev) => (prev ? { ...prev, ...updatePayload } : prev));
        //only update the full student if the quiz is over so the full page doesn't reload each question
        if (isLastQuestion) {
          setLoggedInStudent((prev) => (prev ? { ...prev, personalityType: updatedStudent.personalityType } : prev));
          navigate(PageRoute.PERSONALITY_QUIZ_PREVIEW);
        }
      }
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      // The effect on currentQuestion will re-sync selectedOptions.
    }
  };

  return (
    <PersonalityQuizLayout
      onNext={handleNext}
      onPrevious={handlePrevious}
      showPrevious={currentQuestionIndex > 0}
      isLastQuestion={isLastQuestion}
    >
      <Box sx={{ height: "calc(100vh - 72px)", position: "relative" }}>
        <Box
          sx={{
            background: "#F4F0DC",
            height: "110%",
            width: "90%",
            maxWidth: "600px",
            transform: "rotate(-6deg)",
            borderRadius: "56px 56px 0 0",
            position: "absolute",
            mt: 6,
            top: 0,
            left: 0,
          }}
        />
        <Box
          sx={{
            display: "flex",
            transform: "rotate(-2deg)",
            padding: "46px",
            height: "110%",
            mt: 2,
            flexDirection: "column",
            maxWidth: "700px",
            alignItems: "flex-start",
            flexShrink: 0,
            borderRadius: "56px 56px 0px 0px",
            background: "#FCFBF5",
            boxShadow: "0px 25px 60px 0px rgba(16, 24, 40, 0.20)",
          }}
        >
          {currentQuestion && (
            <>
              {loading ? (
                <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress size={80} />
                </Box>
              ) : (
                <>
                  <QuizProgressBar questionNumber={currentQuestionIndex + 1} totalQuestions={questions.length} />
                  <QuestionCard
                    question={currentQuestion}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </PersonalityQuizLayout>
  );
};

export default PersonalityQuizTake;
