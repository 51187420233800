import { FormikHelpers } from "formik";
import * as Yup from "yup";
import { Collection, PageRoute } from "../../../shared/types/enums";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import useLogger from "../../../shared/hooks/logging/useLogger";
import { LogEventType } from "../../../shared/types/logEnums";
import { useSetRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { AddressComponents, EFCDataRecord, ProviderProgramRecordTwo, StudentRecord } from "../../../shared/types/types";
import useUpdateFSDoc from "../../../shared/hooks/db/useUpdateFSDoc";
import useGenerateProgramRecs from "../recommendationAlgorithm/useGenerateProgramRecs";
import { recommendedProgramsAtom } from "../../recoil/recommendedProgramAtoms";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import { useNavigate } from "react-router-dom";

type Props = {
  cities: any;
  student: StudentRecord;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  mode: "unlock" | "update";
};

const validationSchema = Yup.object({
  gpaValue: Yup.number().required("GPA is required").min(0, "GPA cannot be less than 0"),
  gpaMax: Yup.number()
    .required("GPA Max is required")
    .min(1, "GPA Max cannot be less than 1")
    .max(10, "GPA Max cannot be higher than 10"),
  act: Yup.number().min(1, "ACT Max cannot be less than 0").max(36, "ACT Max cannot be higher than 36"),
  sat: Yup.number().min(400, "SAT Max cannot be less than 400").max(1600, "SAT Max cannot be higher than 1600"),
  efc: Yup.number().min(0, "EFC cannot be less than 0"),
  frlStatus: Yup.string().required("FRL Status is required"),
  programTypeForRecommendations: Yup.string().required("Program Type is required"),
  includeOnlineOnly: Yup.string().oneOf(["true", "false"], "Invalid selection").required("Please select an option"),
});

const unlockValidationSchema = Yup.object({
  gpaValue: Yup.number().required("GPA is required").min(0, "GPA cannot be less than 0"),
  gpaMax: Yup.number()
    .required("GPA Max is required")
    .min(0, "GPA Max cannot be less than 0")
    .max(10, "GPA Max cannot be higher than 10"),
  act: Yup.number().min(1, "ACT Max cannot be less than 0").max(36, "ACT Max cannot be higher than 36"),
  sat: Yup.number().min(400, "SAT Max cannot be less than 400").max(1600, "SAT Max cannot be higher than 1600"),
  efc: Yup.number().min(0, "EFC cannot be less than 0"),
  frlStatus: Yup.string().required("FRL Status is required"),

  address: Yup.object()
    .shape({
      address: Yup.string().nullable(), // Allows null values
      city: Yup.string(),
      state: Yup.string(),
      zip: Yup.string(),
      county: Yup.string(),
    })
    .test("is-valid-selection", "Please select a valid address from the list", function (value) {
      if (!value || !value.address) {
        return true; // Skip validation if address is empty or null
      }
      return true; // Always pass validation if an address is provided
    }),

  programTypeForRecommendations: Yup.string().required("Program Type is required"),
  includeOnlineOnly: Yup.string().oneOf(["true", "false"], "Invalid selection").required("Please select an option"),
});

type FormType = {
  gpaValue: number | string;
  gpaMax: number | string;
  act: string;
  sat: string;
  efc: number;
  frlStatus: string;
  address: AddressComponents;
  statesForRecommendations: string[];
  citiesForRecommendations: string[];
  includeOnlineOnly: string;
  programTypeForRecommendations: string;
};

const useCareerUnlockSurveyForm = ({ cities, student, setDialogOpen, mode }: Props) => {
  const { submitLog } = useLogger();
  const { setFSDoc } = useSetFSDoc();
  const { updateFSDoc } = useUpdateFSDoc();
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const { handleGenerate } = useGenerateProgramRecs();
  const setRecommendedPrograms = useSetRecoilState(recommendedProgramsAtom);
  const [listOfCities, setListOfCities] = useState<string[]>([]);
  const [finanicalData, setFinanicalData] = useState<EFCDataRecord>();
  const navigate = useNavigate();

  useEffect(() => {
    if (mode === "update") {
      const citiesArray: string[] = [];
      student.statesForRecommendations.forEach((state) => {
        const stateCities = cities[state as keyof typeof cities].map((city: string) => `${city}, ${state}`);
        citiesArray.push(...stateCities);
      });
      setListOfCities(citiesArray);
    }
  }, [cities, mode, student.statesForRecommendations]);

  const initialValues: FormType = {
    gpaValue: student.gpaValue || "",
    gpaMax: student.gpaMax || "4",
    act: student.act,
    sat: student.sat,
    efc: student.efc || 0,
    frlStatus: student.frlStatus,
    statesForRecommendations: student.statesForRecommendations || [],
    citiesForRecommendations: student.citiesForRecommendations || [],
    includeOnlineOnly: student.includeOnlineOnly ? "true" : "false",
    programTypeForRecommendations: student.programTypeForRecommendations || "",
    address: student.address || {
      address: "",
      lat: 0,
      lon: 0,
      city: "",
      state: "",
      zip: "",
      county: "",
    },
  };

  const handleSubmit = useCallback(
    async (values: FormType, { setSubmitting }: FormikHelpers<FormType>) => {
      if (!student) return;
      try {
        const citiesOnly = values.citiesForRecommendations.map((city) => city.split(",")[0]);
        const programMatches: ProviderProgramRecordTwo[] = await handleGenerate({
          gpaMax: values.gpaMax === "" ? 4 : parseFloat(String(values.gpaMax)),
          gpaValue: values.gpaValue === "" ? 3 : parseFloat(String(values.gpaValue)),
          programTypeForRecommendations: values.programTypeForRecommendations,
          includeOnlineOnly: values.includeOnlineOnly === "true",
          statesForRecommendations: values.statesForRecommendations,
          citiesForRecommendations: citiesOnly,
          onetCodes: [...student.willowRecommendedJobIds, ...student.favoriteJobIds],
          removedProgramIds: student.removedProgramIds,
          dislikedJobIds: student.dislikedJobIds,
          act: values.act,
        });
        setRecommendedPrograms(programMatches);
        const programMatchIds = programMatches.map((program) => program.id);
        const updatedValues = {
          ...values,
          gpaMax: values.gpaMax === "" ? 4 : parseFloat(String(values.gpaMax)),
          gpaValue: values.gpaValue === "" ? 3 : parseFloat(String(values.gpaValue)),
          includeOnlineOnly: values.includeOnlineOnly === "true",
          setupComplete: true,
          willowRecommendedProgramIds: programMatchIds,
        };

        const promises: Promise<void | string | null | Response>[] = [];

        if (finanicalData) {
          if (finanicalData.id) {
            promises.push(
              updateFSDoc({
                col: Collection.EFCDATA,
                data: { ...finanicalData },
                id: finanicalData.id,
              }),
            );
          } else {
            promises.push(
              setFSDoc({
                col: Collection.EFCDATA,
                data: { ...finanicalData, id: student.id },
                id: student.id,
              }),
            );
          }
        }
        promises.push(
          updateFSDoc({
            col: Collection.STUDENTS,
            data: updatedValues,
            id: student.id,
          }),
        );

        await Promise.all(promises);
        setLoggedInStudent((pV) => (pV ? { ...pV, ...updatedValues } : null));
        submitLog({
          eventType: LogEventType.CAREER_UNLOCK_SURVEY_SUBMITTED,
          file: "useCareerUnlockSurveyForm.ts",
        });
        if (mode === "unlock") {
          navigate(PageRoute.FEED);
        }
      } catch (error) {
        submitLog({
          error,
          snackbarMessage: "There was an error updating your profile, please refresh and try again.",
          eventType: LogEventType.CAREER_UNLOCK_SURVEY_ERROR,
          file: "useCareerUnlockSurveyForm.ts",
        });
      }
      setSubmitting(false);
      setDialogOpen(false);
    },
    [
      student,
      setDialogOpen,
      handleGenerate,
      setRecommendedPrograms,
      finanicalData,
      updateFSDoc,
      setLoggedInStudent,
      submitLog,
      mode,
      setFSDoc,
      navigate,
    ],
  );

  return {
    initialValues,
    validationSchema,
    unlockValidationSchema,
    listOfCities,
    finanicalData,
    setListOfCities,
    setFinanicalData,
    handleSubmit,
  };
};

export default useCareerUnlockSurveyForm;
