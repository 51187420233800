import React, { useState } from "react";
import { Box, TextField, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../hooks/onboarding/useOnboarding";
import OnboardingLayout from "./OnboardingLayout";
import { PageRoute } from "../../../shared/types/enums";

const EmailAndPasswordStep: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { createAuthUser, createStudent } = useOnboarding();
  const navigate = useNavigate();

  const handleContinue = async () => {
    setError(null);

    if (!email || !password || !confirmPassword) {
      setError("All fields are required.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    try {
      const studentId = await createAuthUser(email, password); // Calls Firebase auth to create a new user
      await createStudent(studentId); // Calls Firebase Firestore to create a new student document
      navigate(PageRoute.ONBOARDING_PERSONAL_INFO); // Navigate to the next step
    } catch (err) {
      console.error("Error creating user:", err);
      setError(err instanceof Error ? err.message : "Failed to create account. Please try again.");
    }
  };

  return (
    <OnboardingLayout currentStep={1} handleContinue={handleContinue}>
      <Box sx={{ textAlign: "left", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            mx: "auto",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Welcome to Willow!
          </Typography>
          <Typography sx={{ mt: 1, color: "text.secondary" }}>Let’s set up your account</Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            maxWidth: 500,
            mx: "auto",
          }}
        >
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {error}
            </Alert>
          )}
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default EmailAndPasswordStep;
