import { getFunctionURL } from "./cloudFunctionUtils";

type FetchDataProps = {
  functionName: string;
  payload: any;
};

export const fetchData = async ({ functionName, payload }: FetchDataProps) => {
  const url = getFunctionURL(functionName);
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  return response;
};

export const fetchAPI = async ({
  functionName,
  method,
  payload,
  path,
}: {
  path: string;
  method: string;
  payload?: any; // Make payload optional
  functionName: string;
}) => {
  const baseURL = getFunctionURL(functionName);
  const url =
    method === "GET" && payload
      ? `${baseURL}/${path}?${new URLSearchParams(payload).toString()}`
      : `${baseURL}/${path}`;

  const response = await fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    ...(method !== "GET" && method !== "HEAD" ? { body: JSON.stringify(payload) } : {}),
  });

  return response;
};
