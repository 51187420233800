import { Box, Button, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { StudentRecord } from "../../../shared/types/types";
import CareerUnlockSurveyDialog from "../careerUnlockSurvey/CareerUnlockSurveyDialog";
import StudentCard from "./StudentCard";
import PersonalityType from "./PersonalityType";
import MyWhy from "./MyWhy";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

type Props = {
  loggedInStudent: StudentRecord;
  navOpen: boolean;
};

const StudentProfileSetupNotComplete = ({ loggedInStudent }: Props) => {
  const [unlockMatchesOpen, setUnlockMatchesOpen] = useState(false);
  const { width } = useWindowDimensions();
  const mobile = width < 900;

  useEffect(() => {
    if (loggedInStudent && !loggedInStudent.setupComplete) {
      setUnlockMatchesOpen(true);
    }
  }, [loggedInStudent]);

  return (
    <>
      {loggedInStudent && (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", px: 4, mt: 2 }}>
            <Button sx={{ p: 2 }} variant="contained" onClick={() => setUnlockMatchesOpen(true)} fullWidth size="large">
              Click Here to Unlock Your Career & Program Matches
            </Button>
          </Box>
          {mobile ? (
            <Box
              sx={{
                mb: 7,
                display: "flex",
                justifyContent: "center",
                padding: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                }}
              >
                <StudentCard />
                <PersonalityType loggedInStudent={loggedInStudent} />
                <MyWhy />
              </Box>
            </Box>
          ) : (
            <Box sx={{ p: 2, mb: 12 }}>
              <Container maxWidth="sm">
                <StudentCard />
                <MyWhy />
                <PersonalityType loggedInStudent={loggedInStudent} />
              </Container>
            </Box>
          )}
        </>
      )}
      <CareerUnlockSurveyDialog open={unlockMatchesOpen} setOpen={setUnlockMatchesOpen} mode="unlock" />
    </>
  );
};

export default StudentProfileSetupNotComplete;
