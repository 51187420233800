import React, { memo, useEffect, useMemo, useState } from "react";
import PersonalityQuizLayout from "./PersonalityQuizLayout";
import { Box, Button, Typography } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { fetchAPI } from "../../../shared/utils/fetchUtils";
import { PersonalityTypeRecord } from "../../../shared/types/types";
import { PageRoute } from "../../../shared/types/enums";
import { useNavigate } from "react-router-dom";
import { getProfileImage } from "../../../shared/utils/imageUtils";

const PersonalityQuizPreview = () => {
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const [personalityType, setPersonalityType] = useState<PersonalityTypeRecord | null>(null);
  const navigate = useNavigate();
  console.log("LoggedInStudent", loggedInStudent);

  // Fetch all questions
  useEffect(() => {
    const getPersonalityType = async () => {
      console.log("GETTING PERSONALITY TYPE", loggedInStudent?.personalityType);
      if (!loggedInStudent?.personalityType) return;
      const response = await fetchAPI({
        functionName: "studentsAPI",
        path: "getPersonalityType",
        method: "GET",
        payload: { personalityTypeId: loggedInStudent?.personalityType },
      });
      const data = await response.json();
      setPersonalityType(data);
    };
    getPersonalityType();
  }, [loggedInStudent?.personalityType]);

  const handleNavigateToPressingChallenges = async () => {
    await fetchAPI({
      functionName: "studentsAPI",
      path: "updateStudent",
      method: "POST",
      payload: { updates: { quizComplete: true }, studentId: loggedInStudent?.id },
    });
    setLoggedInStudent((prev) => (prev ? { ...prev, quizComplete: true } : prev));
    navigate(PageRoute.PRESSING_CHALLENGES);
  };

  const superPowers =
    personalityType?.superpowers
      .split(/\s*[-*]\s*/)
      .map((segment) => segment.trim())
      .filter((segment) => segment.length > 0) ?? [];

  console.log("Super Powers", superPowers);

  return (
    <>
      {personalityType && (
        <PersonalityQuizLayout isStartPage={true} isPreviewPage={true}>
          <Box sx={{ mt: 8 }}>
            <Typography sx={{ fontSize: 32, color: "rgba(255,255,255,.6)" }}>
              {loggedInStudent?.firstName}, you are a...
            </Typography>
            <Typography sx={{ fontSize: 48, color: "#fff", fontWeight: "700" }}>{personalityType?.title}</Typography>
            <Typography sx={{ fontSize: 16, color: "rgba(255,255,255,.6)" }}>
              {personalityType?.shortDescription}
            </Typography>
            <Typography sx={{ fontSize: 24, color: "#fff", mt: 2 }}>Your Superpowers</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>
                {superPowers.map((superpower, index) => (
                  <Box sx={{ border: "1px solid #fff", borderRadius: 4, p: 2, mt: 2 }} key={index}>
                    <Typography sx={{ fontSize: 14, color: "#fff" }}>{superpower}</Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                <Box sx={{ mt: 2, backgroundColor: "#F4F0DC", p: 1, borderRadius: 4 }}>
                  <img src={getProfileImage(personalityType?.title)} alt={personalityType?.title} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleNavigateToPressingChallenges}
              sx={{ backgroundColor: "#4A6963", "&:hover": { backgroundColor: "#116E5C" } }}
            >
              Continue
            </Button>
          </Box>
        </PersonalityQuizLayout>
      )}
    </>
  );
};

export default PersonalityQuizPreview;
