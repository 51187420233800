import { PressingChallenge } from "../../types/types";

export const challenges: PressingChallenge[] = [
  {
    id: "climate",
    title: "Protect Our Planet",
    guidingQuestion: "How can we protect the environment and create thriving, sustainable communities?",
    focus: ["Climate action", "Renewable energy", "Conservation", "Sustainable agriculture", "Circular economy models"],
    sampleCareers: [
      "Environmental Scientist or Specialist",
      "Solar Photovoltaic Installer",
      "Urban and Regional Planner",
      "Conservation Scientist",
      "Sustainability Specialist",
      "Climate Change Policy Analyst",
    ],
    imageName: "environmental.png",
  },
  {
    id: "health",
    title: "Strengthen Health & Well-Being for All",
    guidingQuestion: "How can we ensure every individual has the care, resources, and support to lead a healthy life?",
    focus: [
      "Public health",
      "Preventive medicine",
      "Mental health awareness",
      "Healthcare equity",
      "Fitness",
      "Nutrition",
    ],
    sampleCareers: [
      "Family and General Practitioner",
      "Registered Nurse",
      "Mental Health Counselor",
      "Health Educator",
      "Dietitian and Nutritionist",
      "Athletic Trainer",
      "Physical Therapist",
    ],
    imageName: "nurse.png",
  },
  {
    id: "education",
    title: "Empower Lives Through Education",
    guidingQuestion: "How can we empower people through education to achieve their full potential in a changing world?",
    focus: ["Education access", "Career readiness", "Workforce development"],
    sampleCareers: [
      "Elementary School Teacher",
      "Career Counselor",
      "Education Administrator",
      "Instructional Designer",
      "Tutor",
    ],
    imageName: "teacher.png",
  },
  {
    id: "business",
    title: "Expand Economic Opportunity & Entrepreneurship",
    guidingQuestion: "How can we foster stable, meaningful work and financial security for people around the globe?",
    focus: ["Entrepreneurship", "Financial literacy", "Small business development", "Economic growth"],
    sampleCareers: [
      "Personal Financial Advisor",
      "Training and Development Specialist",
      "General and Operations Manager",
      "Sales Representative",
      "Marketing Specialist",
      "Advertising Sales Agent",
    ],
    imageName: "business.png",
  },
  {
    id: "tech",
    title: "Harness Technology & Science Ethically",
    guidingQuestion:
      "How can we leverage innovation responsibly to improve lives while safeguarding ethics, privacy, and social well-being?",
    focus: ["Ethical AI", "Cybersecurity", "Digital equity", "Data privacy", "Tech-driven problem-solving"],
    sampleCareers: [
      "Software Developer",
      "Biochemist",
      "Cybersecurity Analyst",
      "Data Scientist",
      "UX Designer",
      "Compliance Officer",
      "Robotics Engineer",
    ],
    imageName: "tech.png",
  },
  {
    id: "justice",
    title: "Build Strong, Just Communities",
    guidingQuestion: "How can we ensure fairness, respect, and equal opportunities in every community?",
    focus: [
      "Civil and human rights",
      "Community-building",
      "Equity in education and healthcare",
      "Diversity and inclusion efforts",
    ],
    sampleCareers: [
      "Lawyer",
      "Legislator",
      "Community Service Manager",
      "Social Worker",
      "Equal Opportunity Representative and Officer",
      "Political Scientist",
      "Mediator",
    ],
    imageName: "protect.png",
  },
  {
    id: "culture",
    title: "Enrich Lives Through Arts, Culture, & Entertainment",
    guidingQuestion:
      "How can creativity, cultural heritage, and shared experiences bring people together and foster understanding?",
    focus: [
      "Artistic expression",
      "Cultural preservation",
      "Creative industries",
      "Community arts initiatives",
      "Media literacy",
      "Sports",
      "Hospitality",
    ],
    sampleCareers: [
      "Fine Artist",
      "Musician",
      "Filmmaker",
      "Museum Curator",
      "Art Therapist",
      "Cultural Program Coordinator",
      "Athlete",
      "Coach",
      "Hospitality Manager",
      "Tour Guide",
    ],
    imageName: "culture.png",
  },
  {
    id: "transit",
    title: "Keep Society Moving & Connected",
    guidingQuestion: "How can we design, build, and maintain the infrastructure and services people rely on every day?",
    focus: ["Construction", "Logistics", "Transportation", "Essential services"],
    sampleCareers: [
      "Electrician",
      "Plumber",
      "Construction Manager",
      "Truck Driver",
      "Warehouse Manager",
      "Maintenance Technician",
      "Supply Chain Specialist",
      "Flight Attendant",
    ],
    imageName: "transit.png",
  },
];
