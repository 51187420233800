import React, { useState } from "react";
import PressingChallengesLayout from "./PressingChallengesLayout";
import { Box, Typography } from "@mui/material";
import { challenges } from "../../../shared/assets/data/pressingChallenges";
import PressingGlobalChallenge from "./PressingGlobalChallenge";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { StudentRecord } from "../../../shared/types/types";
import { fetchAPI } from "../../../shared/utils/fetchUtils";

const PressingChallengesContainer = () => {
  const [step, setStep] = useState(1);
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);

  const currentChallenge = challenges[step - 1];
  const currentScore = loggedInStudent?.pressingChallengeScores.find((item) => item.id === currentChallenge.id)?.score;

  // Called when the user selects a score (1-5) for the current challenge.
  const updateLocalScore = (score: number) => {
    setLoggedInStudent((prev) => {
      if (!prev) return prev;
      const existingIndex = prev.pressingChallengeScores.findIndex((item) => item.id === currentChallenge.id);
      let newScores;
      if (existingIndex !== -1) {
        // Update existing score
        newScores = [...prev.pressingChallengeScores];
        newScores[existingIndex] = { id: currentChallenge.id, score };
      } else {
        // Add new score
        newScores = [...prev.pressingChallengeScores, { id: currentChallenge.id, score }];
      }
      return { ...prev, pressingChallengeScores: newScores };
    });
  };

  // Called when the user clicks "Continue" or "Submit"
  const handleContinue = async () => {
    if (!currentScore) {
      // Optionally notify the user that a score is required.
      return;
    }
    const updatedData: Partial<StudentRecord> = {
      pressingChallengeScores: loggedInStudent.pressingChallengeScores,
    };

    // On the last challenge, mark the challenges as complete.
    if (step === 8) {
      updatedData.pressingChallengesComplete = true;
    }

    const updatePayload = {
      studentId: loggedInStudent.id,
      updates: updatedData,
    };

    try {
      const updateResponse = await fetchAPI({
        functionName: "studentsAPI",
        path: "updateStudent",
        method: "POST",
        payload: updatePayload,
      });
      // Update the local student state with what the backend returns (or with our updates).
      setLoggedInStudent((prev) => (prev ? { ...prev, ...updatedData } : prev));
      if (step < 8) {
        setStep(step + 1);
      } else {
        // Optionally navigate to a success page or display a confirmation.
      }
    } catch (error) {
      console.error("Error updating student:", error);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <PressingChallengesLayout
      currentStep={step}
      handleContinue={handleContinue}
      handleBack={handleBack}
      disableContinue={!currentScore} // Disable if no score selected
    >
      <Box sx={{ backgroundColor: "#FFF", borderRadius: 4, p: 4 }}>
        <Typography
          sx={{
            color: "#171918",
            fontSize: "28px",
            fontWeight: 700,
            lineHeight: "130%",
            textAlign: "left",
            mt: 0,
            mb: 1,
          }}
        >
          How interested are you in these pressing global challenges?
        </Typography>
        <PressingGlobalChallenge
          challenge={currentChallenge}
          currentScore={currentScore}
          onScoreChange={updateLocalScore}
        />
      </Box>
    </PressingChallengesLayout>
  );
};

export default PressingChallengesContainer;
