import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { PressingChallenge } from "../../../shared/types/types";

type Props = {
  challenge: PressingChallenge;
  currentScore?: number;
  onScoreChange: (newScore: number) => void;
};

const PressingGlobalChallenge = ({ challenge, currentScore, onScoreChange }: Props) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
    if (newValue !== null) {
      onScoreChange(Number(newValue));
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ pr: 1 }}>
          <Typography sx={{ color: "#171918", fontSize: "24px", fontWeight: 600, lineHeight: "28.8px", mb: 1 }}>
            {challenge.title}
          </Typography>
          <Typography sx={{ fontSize: "16px" }}>{challenge.guidingQuestion}</Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: 600, mt: 2 }}>Focus</Typography>
          <Box sx={{ pl: 2 }}>
            <Typography>{challenge.focus.join(", ")}</Typography>
          </Box>
          <Typography sx={{ fontSize: "16px", fontWeight: 600, mt: 2 }}>Sample Careers</Typography>
          <Box component="ul" sx={{ pl: 4 }}>
            {challenge.sampleCareers.map((career, index) => (
              <Box component="li" key={index}>
                <Typography>{career}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: "100%", height: "100%", borderRadius: 4 }}>
            <img
              style={{ borderRadius: "12px" }}
              width="100%"
              alt={challenge.title}
              src={`/static/images/challenges/${challenge.imageName}`}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px", mt: 2 }}>
        <ToggleButtonGroup
          value={currentScore !== undefined ? currentScore.toString() : null}
          exclusive
          onChange={handleChange}
          aria-label="interest level"
          sx={{
            borderRadius: "8px",
            width: "100%",
            "& .MuiToggleButton-root": {
              border: "1px solid #ccc",
              flex: 1,
              padding: "12px 26px",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
              "&.Mui-selected": {
                backgroundColor: "#EDFCF9",
                color: "#000",
              },
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            },
          }}
        >
          <ToggleButton value="1">1</ToggleButton>
          <ToggleButton value="2">2</ToggleButton>
          <ToggleButton value="3">3</ToggleButton>
          <ToggleButton value="4">4</ToggleButton>
          <ToggleButton value="5">5</ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Typography sx={{ fontSize: "14px", color: "#666" }}>Not interested at all</Typography>
          <Typography sx={{ fontSize: "14px", color: "#666" }}>Extremely interested</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PressingGlobalChallenge;
