import React from "react";
import { QuestionRecord } from "../../../shared/types/types";
import { Box, Typography } from "@mui/material";
import MCQuestionOption from "./MCQuestionOption";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";

type Props = {
  question: QuestionRecord;
  selectedOptions: string[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<string[]>>;
};

const QuestionCard = ({ question, selectedOptions, setSelectedOptions }: Props) => {
  // If there are exactly 2 options, allow only one selection.
  const maxSelections = question.options.length === 2 ? 1 : 2;
  const { width } = useWindowDimensions();
  const marginTop = width > 1600 ? 8 : width > 1200 ? 6 : 4;

  return (
    <Box sx={{ mt: marginTop }}>
      <Typography variant="h4" sx={{ fontSize: "32px" }}>
        {question.questionText}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>
        Select your top {maxSelections === 1 ? "option" : "and second top option"} that best describes you.
      </Typography>
      <Box sx={{ mt: 4 }}>
        {question.options.map((option, index) => (
          <MCQuestionOption
            key={index}
            option={option}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            maxSelections={maxSelections}
          />
        ))}
      </Box>
    </Box>
  );
};

export default QuestionCard;
