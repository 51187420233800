import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PageRoute, UserType } from "../types/enums";
import { useAuth } from "../contexts/AuthContext";
import { loggedInStudentAtom, userTypeAtom } from "../recoil/userAtoms";

interface CustomRouteProps {
  children: React.ReactNode;
  permission: UserType[];
  originRoute?: string;
  redirectIfNotAuthenticated?: PageRoute;
  redirectIfNoAccount?: PageRoute;
  redirectIfNoPermission?: PageRoute;
  path?: string;
}

const onboardingRoutes: Record<number, PageRoute> = {
  1: PageRoute.ONBOARDING_EMAIL_PASSWORD,
  2: PageRoute.ONBOARDING_PERSONAL_INFO,
  3: PageRoute.ONBOARDING_SCHOOL_INFO,
  4: PageRoute.ONBOARDING_MY_WHY,
  5: PageRoute.ONBOARDING_FEEDBACK,
  6: PageRoute.ONBOARDING_FEEDBACK,
  7: PageRoute.ONBOARDING_THANK_YOU,
};

const CustomRoute: React.FC<CustomRouteProps> = ({ children, permission }) => {
  console.log("CUSTOM ROUTE RENDERED");
  const { currentAuthUser } = useAuth();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const userType = useRecoilValue(userTypeAtom);
  const location = useLocation();

  const currentPath = location.pathname;

  // Public routes or signup are always accessible
  if (!currentAuthUser) {
    if (
      permission.includes("public" as UserType) ||
      currentPath.startsWith(PageRoute.STUDENT_SIGNUP) ||
      currentPath === PageRoute.ONBOARDING_EMAIL_PASSWORD
    ) {
      console.log("Public route or signup route starting with /student-signup, current path: ", currentPath);
      return <>{children}</>;
    }
    console.log("Redirected to Login, current path: ", currentPath);
    return <Navigate to={PageRoute.LOGIN} replace />;
  }

  if (loggedInStudent && !loggedInStudent.setupComplete) {
    const targetRoute = onboardingRoutes[loggedInStudent.onboardingStage];

    if (targetRoute) {
      if (currentPath === targetRoute) {
        return <>{children}</>;
      } else {
        return <Navigate to={targetRoute} />;
      }
    }
  }

  /**************** Internal Only Routes ****************/
  if (currentPath === PageRoute.INTERNAL_COLLEGE_IMAGE_UPLOADER) {
    const acceptedEmails = ["andrew@mk-dir.com", "willowed.org"];
    if (currentAuthUser && acceptedEmails.includes(currentAuthUser.email!.split("@")[1])) {
      return <>{children}</>;
    } else {
      return <Navigate to={PageRoute.DASHBOARD} replace />;
    }
  }

  if (currentPath === PageRoute.UPLOAD_PROFESSIONAL_PROGRAMS) {
    const acceptedEmails = ["andrew@mk-dir.com", "ryan@willowed.org"];
    if (currentAuthUser && acceptedEmails.includes(currentAuthUser.email!)) {
      return <>{children}</>;
    } else {
      return <Navigate to={PageRoute.DASHBOARD} replace />;
    }
  }
  /**************** End Internal Only Routes ****************/

  if (userType === UserType.STUDENT) {
    if (!loggedInStudent) return null;
    const { quizComplete, setupComplete, lastQuestionId, pressingChallengesComplete } = loggedInStudent;

    // Redirect based on onboarding progress
    if (currentPath === PageRoute.LOGIN && setupComplete) {
      return <Navigate to={PageRoute.FEED} replace />;
    }
    if (currentPath === PageRoute.LOGIN && !setupComplete) {
      return <Navigate to={PageRoute.STUDENT_PROFILE} replace />;
    }

    if (!quizComplete && !lastQuestionId && !currentPath.startsWith("/personality-quiz")) {
      return <Navigate to={PageRoute.PERSONALITY_QUIZ_START} />;
    } else if (!quizComplete && !lastQuestionId && currentPath === PageRoute.PERSONALITY_QUIZ_START) {
      return <>{children}</>;
    } else if (!quizComplete && lastQuestionId && !currentPath.startsWith("/personality-quiz")) {
      console.log("Going back to take");
      return <Navigate to={PageRoute.PERSONALITY_QUIZ_TAKE} />;
    } else if (!quizComplete && currentPath === PageRoute.PERSONALITY_QUIZ_TAKE) {
      return <>{children}</>;
    } else if (!quizComplete && currentPath === PageRoute.PERSONALITY_QUIZ_PREVIEW) {
      return <>{children}</>;
    }

    if (!setupComplete && !pressingChallengesComplete && currentPath !== PageRoute.PRESSING_CHALLENGES) {
      return <Navigate to={PageRoute.PRESSING_CHALLENGES} />;
    } else if (!pressingChallengesComplete && currentPath === PageRoute.PRESSING_CHALLENGES) {
      return <>{children}</>;
    }
    if (!setupComplete && currentPath !== PageRoute.RECOMMENDATION_PREFERENCES) {
      return <Navigate to={PageRoute.RECOMMENDATION_PREFERENCES} replace />;
    } else if (!setupComplete && currentPath === PageRoute.RECOMMENDATION_PREFERENCES) {
      return <>{children}</>;
    }

    if (permission.includes(UserType.STUDENT)) {
      return <>{children}</>;
    }
    return <Navigate to={PageRoute.FEED} replace />;
  }

  if (userType === UserType.TEACHER || userType === UserType.ADMIN) {
    if (currentPath === PageRoute.LOGIN) {
      return <Navigate to={PageRoute.STAFF_DASHBOARD} replace />;
    }
    if (permission.includes(userType)) {
      return <>{children}</>;
    }
    return <Navigate to={PageRoute.STAFF_DASHBOARD} replace />;
  }

  return <>{children}</>;
};

export default CustomRoute;
