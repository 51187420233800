import React from "react";
import OnboardingLayout from "./OnboardingLayout";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "../../../shared/types/enums";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useOnboarding } from "../../hooks/onboarding/useOnboarding";

const ThankYouStep: React.FC = () => {
  const navigate = useNavigate();
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const { updateStudentData } = useOnboarding();

  const handleContinueToQuiz = () => {
    setLoggedInStudent((prev) => prev && { ...prev, onboardingStage: 8 });
    updateStudentData({ onboardingStage: 8 });
    navigate(PageRoute.PERSONALITY_QUIZ_START);
  };

  const handleBack = () => {
    setLoggedInStudent((prev) => prev && { ...prev, onboardingStage: 6 });
    navigate(PageRoute.ONBOARDING_FEEDBACK);
  };

  return (
    <OnboardingLayout currentStep={7} handleBack={handleBack}>
      <Box sx={{ textAlign: "center", mt: 4 }}>
        <Typography variant="h4" fontWeight="bold">
          Thank you for signing up!
        </Typography>
        <Typography sx={{ mt: 2, color: "text.secondary", maxWidth: 600, mx: "auto" }}>
          We’ll use the data you just shared to tailor the best career paths, programs, and college recommendations for
          you.
        </Typography>
        <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleContinueToQuiz} sx={{ px: 4, py: 1.5 }}>
            Continue to Personality Quiz
          </Button>
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default ThankYouStep;
