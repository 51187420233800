import React from "react";
import PersonalityQuizLayout from "./PersonalityQuizLayout";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "../../../shared/types/enums";

const PersonalityQuizStart = () => {
  const navigate = useNavigate();
  return (
    <PersonalityQuizLayout isStartPage={true}>
      <Box>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: "#E5E7E6", fontSize: "46px", fontWeight: 700, lineHeight: "130%", textAlign: "center" }}
        >
          Willow's personality quiz: Where your journey to self-discovery begins
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Typography sx={{ backgroundColor: "#0B7514", color: "#F2F3F2", fontWeight: 700, fontSize: "20px", p: 0.5 }}>
            There are no wrong answers!
          </Typography>
        </Box>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Typography sx={{ color: "#F2F3F2", fontSize: "16px" }}>
            The more honest you are, the more insightful your results will be.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            variant="contained"
            sx={{ px: 4, py: 1.5, backgroundColor: "rgba(229, 247, 244, 0.30)", borderRadius: 4 }}
            onClick={() => navigate(PageRoute.PERSONALITY_QUIZ_TAKE)}
          >
            Start the Quiz
          </Button>
        </Box>
      </Box>
    </PersonalityQuizLayout>
  );
};

export default PersonalityQuizStart;
