import { Box, Grid, Typography } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { Color, ProjectionGrowthOption } from "../../../types/enums";
import useGetWageData from "../../../hooks/exploreCareers/jobDetails/useGetWageData";
import { JobDetailsRecord, WageDataRecord } from "../../../types/types";
import { formatCurrency } from "../../../utils/formatUtils";

type Props = {
  relatedJobs: JobDetailsRecord[];
};

interface JobDataWithWages extends JobDetailsRecord {
  wageData: WageDataRecord;
}

const CareerTable = ({ relatedJobs }: Props) => {
  const [jobDataWithWages, setJobDataWithWages] = useState<JobDataWithWages[]>([]);

  const { getWageData } = useGetWageData();

  useEffect(() => {
    const callGetWageData = async () => {
      try {
        const promises = relatedJobs.map(async (relatedJob, index) => {
          const DataResponse = await getWageData({
            city: "",
            state: "",
            onetCode: relatedJob.id,
          });

          return {
            jobIndex: index,
            ...relatedJob,
            wageData: DataResponse.wageData,
          };
        });

        const result = await Promise.all(promises);

        setJobDataWithWages(result as SetStateAction<JobDataWithWages[]>);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (relatedJobs && relatedJobs.length > 0) {
      callGetWageData();
    }
  }, [getWageData, relatedJobs, setJobDataWithWages]);

  const formatGrowth = (value: string | null) => {
    const lowercase = value?.toLowerCase();
    switch (lowercase) {
      case ProjectionGrowthOption.DECLINE:
        return "Declining";
      case ProjectionGrowthOption.LITTLE_OR_NO_CHANGE:
        return "Low Growth";
      case ProjectionGrowthOption.FASTER_THAN_AVERAGE:
        return "Good";
      case ProjectionGrowthOption.MUCH_FASTER_THAN_AVERAGE:
        return "Great";
      case ProjectionGrowthOption.AVERAGE:
        return "Average";
      default:
        return value;
    }
  };

  return (
    <>
      <Box sx={{ backgroundColor: "#BBEBE1", position: "relative", pt: 1 }}>
        <Box
          sx={{
            backgroundColor: "rgba(150, 150, 150, 0.2)",
            position: "absolute",
            top: 25,
            left: "35%",
            width: "45%",
            height: "calc(100% - 20px)",
          }}
        ></Box>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Typography
              sx={{
                paddingLeft: "4px",
                fontSize: 20,
                fontWeight: 700,
                mt: -0.5,
              }}
            >
              Potential Careers
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 800, fontSize: 12 }}>
              ESTIMATED SALARY
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid container spacing={0} sx={{ borderBottom: `3px solid ${Color.CHARCOAL}` }}>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              ENTRY
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              MEDIUM
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              HIGH
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 12 }}>
              CAREER PROSPECT
            </Typography>
          </Grid>
        </Grid>
        {jobDataWithWages?.map((job, index) => (
          <Grid
            key={index}
            container
            spacing={0}
            sx={{ borderBottom: jobDataWithWages.length === index + 1 ? "none" : `1px solid ${Color.CHARCOAL}` }}
          >
            <Grid item xs={4} sx={{ padding: 1 }}>
              <Typography textAlign={"left"} sx={{ fontWeight: 400, fontSize: 12 }}>
                {job.title}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
                {job.wageData?.A_PCT10 ? formatCurrency({ amount: parseInt(job.wageData?.A_PCT10) }) : "Unknown"}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
                {job.wageData?.A_MEDIAN ? formatCurrency({ amount: parseInt(job.wageData?.A_MEDIAN) }) : "Unknown"}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <Typography textAlign={"center"} sx={{ fontWeight: 400, fontSize: 15 }}>
                {job.wageData?.A_PCT90 ? formatCurrency({ amount: parseInt(job.wageData?.A_PCT90) }) : "Unknown"}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              {/* TODO: */}
              <Typography textAlign={"center"} sx={{ fontWeight: 600, fontSize: 15 }}>
                {formatGrowth(job.projectedGrowth)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default CareerTable;
