import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, updateDoc, setDoc } from "firebase/firestore";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { Collection, UserType } from "../../../shared/types/enums";
import useSetFSDoc from "../../../shared/hooks/db/useSetFSDoc";
import { StudentRecord } from "../../../shared/types/types";
import { parseSingleStudentResponse } from "../../../shared/utils/parserUtils";

export const useOnboarding = () => {
  const [student, setStudent] = useRecoilState(loggedInStudentAtom);
  const auth = getAuth();
  const db = getFirestore();
  const { setFSDoc } = useSetFSDoc();

  const createAuthUser = async (email: string, password: string) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user.uid;
  };

  const createStudent = async (studentId: string) => {
    const results = await setFSDoc<StudentRecord>({
      col: Collection.STUDENTS,
      id: studentId,
      data: { userType: UserType.STUDENT, onboardingStage: 2 },
    });
    if (!results) throw new Error("Failed to create student");
    const parsedStudentData = parseSingleStudentResponse(results);
    setStudent(parsedStudentData);
  };

  const updateStudentData = async (data: object) => {
    if (!student) throw new Error("No student logged in");
    const studentRef = doc(db, "students", student.id);
    await updateDoc(studentRef, data);
    const parsedStudentData = parseSingleStudentResponse({ ...student, ...data });
    setStudent(parsedStudentData);
  };

  return { createAuthUser, updateStudentData, createStudent };
};
