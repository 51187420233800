import { Box } from "@mui/material";
import React from "react";

type Props = {
  questionNumber: number;
  totalQuestions: number;
};

const QuizProgressBar: React.FC<Props> = ({ questionNumber, totalQuestions }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%", // Ensure it takes the full width of the parent
        mt: 0,
      }}
    >
      {[...Array(totalQuestions)].map((_, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: index < questionNumber ? "#616A66" : "#D9D9D9",
            height: 10,
            flex: 1, // Ensures equal spacing
            maxWidth: "20px", // Prevents excessive stretching
            borderRadius: "999px",
            transition: "background-color 0.3s ease-in-out",
            mx: "2px", // Adds small spacing to avoid touching edges
          }}
        />
      ))}
    </Box>
  );
};

export default QuizProgressBar;
