import React, { useState, useEffect } from "react";
import { Box, Typography, Button, ButtonGroup } from "@mui/material";
import OnboardingLayout from "./OnboardingLayout";
import { useOnboarding } from "../../hooks/onboarding/useOnboarding";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "../../../shared/types/enums";

const FeedbackStep: React.FC = () => {
  const [clarity, setClarity] = useState<number | null>(null);
  const [preparedness, setPreparedness] = useState<number | null>(null);
  const [error, setError] = useState(false);
  const { updateStudentData } = useOnboarding();
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedInStudent?.personalFeedback) {
      const feedback = loggedInStudent.personalFeedback;
      setClarity(feedback.find((q) => q.questionId === 5)?.score || null);
      setPreparedness(feedback.find((q) => q.questionId === 6)?.score || null);
    }
  }, [loggedInStudent]);

  const handleContinue = async () => {
    const currentStep = loggedInStudent?.onboardingStage || 5;
    const currentAnswer = currentStep === 5 ? clarity : preparedness;

    if (currentAnswer === null) {
      setError(true);
      return;
    }

    const feedbackEntry = {
      questionText:
        currentStep === 5
          ? "How clear are you on what you want your career and life after high school to be like?"
          : "How prepared do you feel for life after high school?",
      score: currentAnswer,
      submittedOn: new Date().toISOString(),
      questionId: currentStep,
    };

    try {
      const updatedFeedback =
        currentStep === 5 ? [feedbackEntry] : [...(loggedInStudent?.personalFeedback || []), feedbackEntry];

      await updateStudentData({
        personalFeedback: updatedFeedback,
        onboardingStage: currentStep + 1,
      });

      setLoggedInStudent((prev) =>
        prev ? { ...prev, personalFeedback: updatedFeedback, onboardingStage: currentStep + 1 } : prev,
      );

      if (currentStep === 5) {
        navigate(PageRoute.ONBOARDING_FEEDBACK);
      } else if (currentStep === 6) {
        navigate(PageRoute.ONBOARDING_THANK_YOU);
      }
    } catch (error) {
      console.error("Error updating feedback:", error);
    }
  };

  const handleBack = () => {
    setLoggedInStudent((prev) => prev && { ...prev, onboardingStage: currentStep === 6 ? 5 : 4 });
    navigate(currentStep === 6 ? PageRoute.ONBOARDING_FEEDBACK : PageRoute.ONBOARDING_MY_WHY);
  };

  const renderButtonGroup = (value: number | null, setter: (value: number) => void) => (
    <ButtonGroup fullWidth>
      {[...Array(10)].map((_, index) => (
        <Button
          key={index + 1}
          variant={value === index + 1 ? "contained" : "outlined"}
          onClick={() => {
            setter(index + 1);
            setError(false);
          }}
        >
          {index + 1}
        </Button>
      ))}
    </ButtonGroup>
  );

  const currentStep = loggedInStudent?.onboardingStage || 5;

  return (
    <OnboardingLayout currentStep={currentStep} handleContinue={handleContinue} handleBack={handleBack}>
      <Box sx={{ textAlign: "left", mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: 500, mx: "auto" }}>
          <Typography variant="h4" fontWeight="bold">
            Feedback
          </Typography>
          <Typography sx={{ mt: 1, color: "text.secondary" }}>
            Your feedback helps us understand how you're feeling about your future.
          </Typography>
        </Box>
        <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 3, width: "100%", maxWidth: 500, mx: "auto" }}>
          {currentStep === 5 ? (
            <Box>
              <Typography variant="h6">
                How clear are you on what you want your career and life after high school to be like?
              </Typography>
              {renderButtonGroup(clarity, setClarity)}
            </Box>
          ) : (
            <Box>
              <Typography variant="h6">How prepared do you feel for life after high school?</Typography>
              {renderButtonGroup(preparedness, setPreparedness)}
            </Box>
          )}
          {error && (
            <Typography color="error" sx={{ textAlign: "center" }}>
              Please select an option before continuing.
            </Typography>
          )}
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default FeedbackStep;
