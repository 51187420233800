import { createTheme } from "@mui/material/styles";
import { Color } from "./shared/types/enums";

declare module "@mui/material/Chip" {
  export interface ChipPropsColorOverrides {
    gray: true;
    white: true;
    mint: true;
    lightPink: true;
    lightBlue: true;
    lightOrange: true;
    lightGreen: true;
    lightPurple: true;
    surfaceGreen: true;
    safetyLevelColor: true;
    targetLevelColor: true;
    reachLevelColor: true;
    farReachLevelColor: true;
  }
}

declare module "@mui/material/Button" {
  export interface ButtonPropsColorOverrides {
    gray: true;
    white: true;
    mint: true;
    close: true;
  }
}

declare module "@mui/material/AppBar" {
  export interface AppBarPropsColorOverrides {
    gray: true;
    white: true;
    mint: true;
    surfaceGreen: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    cardTitle: React.CSSProperties;
    subHeading: React.CSSProperties;
    cardProviderName: React.CSSProperties;
    cardLocation: React.CSSProperties;
    cardProgramName: React.CSSProperties;
    pageTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
    subHeading?: React.CSSProperties;
    cardProviderName?: React.CSSProperties;
    cardLocation?: React.CSSProperties;
    cardProgramName?: React.CSSProperties;
    pageTitle?: React.CSSProperties;
  }
}

declare module "@mui/material/styles" {
  interface PaletteOptions {
    gray: {
      main: string;
      contrastText: string;
    };
    close: {
      main: string;
      contrastText: string;
    };
    white: {
      main: string;
      contrastText: string;
    };
    charcoal: {
      main: string;
      contrastText: string;
    };
    chalkboard: {
      main: string;
      contrastText: string;
    };
    mint: {
      main: string;
      contrastText: string;
    };
    chalk: {
      main: string;
      contrastText: string;
    };
    black: {
      main: string;
      contrastText: string;
    };
    lightPink: {
      main: string;
      contrastText: string;
    };
    lightBlue: {
      main: string;
      contrastText: string;
    };
    lightOrange: {
      main: string;
      contrastText: string;
    };
    lightGreen: {
      main: string;
      contrastText: string;
    };
    lightPurple: {
      main: string;
      contrastText: string;
    };
    surfaceGreen: {
      main: string;
      contrastText: string;
    };
    safetyLevelColor: {
      main: string;
      contrastText: string;
    };
    targetLevelColor: {
      main: string;
      contrastText: string;
    };
    reachLevelColor: {
      main: string;
      contrastText: string;
    };
    farReachLevelColor: {
      main: string;
      contrastText: string;
    };
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
    leadCardTitle: true;
    leadCardDate: true;
    leadCardInfo: true;
    subHeading: true;
    cardProviderName: true;
    cardLocation: true;
    cardProgramName: true;
    pageTitle: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: Color.MINT,
      contrastText: Color.WHITE,
    },
    secondary: {
      main: Color.CHALKBOARD,
      contrastText: Color.WHITE,
    },
    close: {
      main: "#555",
      contrastText: "#fff",
    },
    white: {
      main: Color.WHITE,
      contrastText: Color.BLACK,
    },
    gray: {
      main: Color.GRAY_200,
      contrastText: Color.BLACK,
    },
    charcoal: {
      main: Color.CHARCOAL,
      contrastText: Color.WHITE,
    },
    chalkboard: {
      main: Color.CHALKBOARD,
      contrastText: Color.WHITE,
    },
    mint: {
      main: Color.MINT,
      contrastText: Color.WHITE,
    },
    chalk: {
      main: Color.CHALK,
      contrastText: Color.BLACK,
    },
    black: {
      main: Color.BLACK,
      contrastText: Color.CHALK,
    },
    lightPink: {
      main: Color.LIGHT_PINK,
      contrastText: Color.DARK_PINK,
    },
    lightBlue: {
      main: Color.LIGHT_BLUE,
      contrastText: Color.DARK_BLUE,
    },
    lightOrange: {
      main: Color.LIGHT_ORANGE,
      contrastText: Color.DARK_ORANGE,
    },
    lightGreen: {
      main: Color.LIGHT_GREEN,
      contrastText: Color.DARK_GREEN,
    },
    lightPurple: {
      main: Color.LIGHT_PURPLE,
      contrastText: Color.DARK_PURPLE,
    },
    surfaceGreen: {
      main: Color.SURFACE_GREEN,
      contrastText: Color.CHALKBOARD,
    },
    safetyLevelColor: {
      main: Color.ELECTRIC_BLUE,
      contrastText: Color.WHITE,
    },
    targetLevelColor: {
      main: Color.MINT,
      contrastText: Color.SURFACE_GREEN,
    },
    reachLevelColor: {
      main: Color.ORANGE_700,
      contrastText: Color.SOFT_WHITE,
    },
    farReachLevelColor: {
      main: Color.PINK,
      contrastText: Color.WHITE,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    h1: {
      fontSize: 56,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h2: {
      fontSize: 44,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h3: {
      fontSize: 32,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h4: {
      fontSize: 24,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h5: {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    h6: {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    subHeading: {
      fontSize: 18,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
    },
    body1: {
      fontSize: 13,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
    },
    cardProviderName: {
      fontSize: 16,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      color: Color.MINT,
    },
    cardLocation: {
      fontSize: 12,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 400,
      color: Color.BLACK,
    },
    cardProgramName: {
      fontSize: 20,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      color: Color.CHALK,
    },
    pageTitle: {
      fontSize: 30,
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 700,
      color: "#13352E",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "medium", // Ensure that medium is the default if no size is provided
      },
      variants: [
        // === Primary (Contained, Primary) Variants ===
        {
          props: { variant: "contained", color: "primary", size: "small" },
          style: {
            display: "inline-flex",
            padding: "8px 12px", // small
            justifyContent: "center",
            alignItems: "center",
            gap: "4px", // small
            borderRadius: "12px",
            backgroundColor: "var(--Willow-Green-600, #0C4F42)",
            color: "var(--White, #FFF)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px", // small
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "none",
            lineHeight: "normal",
            "&:hover": {
              backgroundColor: "var(--Willow-Green-700, #072C25)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "var(--Willow-Green-600, #0C4F42)",
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #3C81F1",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--Neutral-100, #F5F5F5)",
              color: "var(--Neutral-500, #717680)",
            },
          },
        },
        {
          props: { variant: "contained", color: "primary", size: "medium" },
          style: {
            display: "inline-flex",
            padding: "8px 18px", // medium
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "12px",
            backgroundColor: "var(--Willow-Green-600, #0C4F42)",
            color: "var(--White, #FFF)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px", // medium
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "none",
            lineHeight: "normal",
            "&:hover": {
              backgroundColor: "var(--Willow-Green-700, #072C25)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "var(--Willow-Green-600, #0C4F42)",
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #3C81F1",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--Neutral-100, #F5F5F5)",
              color: "var(--Neutral-500, #717680)",
            },
          },
        },
        {
          props: { variant: "contained", color: "primary", size: "large" },
          style: {
            display: "inline-flex",
            padding: "8px 20px", // large
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "12px",
            backgroundColor: "var(--Willow-Green-600, #0C4F42)",
            color: "var(--White, #FFF)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "20px", // large
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "none",
            lineHeight: "normal",
            "&:hover": {
              backgroundColor: "var(--Willow-Green-700, #072C25)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "var(--Willow-Green-600, #0C4F42)",
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #3C81F1",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--Neutral-100, #F5F5F5)",
              color: "var(--Neutral-500, #717680)",
            },
          },
        },

        // === Secondary (Outlined, Secondary) Variants ===
        {
          props: { variant: "outlined", color: "primary", size: "small" },
          style: {
            display: "inline-flex",
            padding: "8px 12px", // small
            justifyContent: "center",
            alignItems: "center",
            gap: "4px", // small
            borderRadius: "12px",
            border: "1px solid var(--Neutral-300, #D5D7DA)",
            backgroundColor: "#FFF",
            color: "var(--Willow-Green-600, #0C4F42)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px", // small
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "none",
            lineHeight: "normal",
            "&:hover": {
              backgroundColor: "var(--Willow-Green-25, #E5F7F4)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#FFF",
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #3C81F1",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--Neutral-100, #F5F5F5)",
              color: "var(--Neutral-500, #717680)",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary", size: "medium" },
          style: {
            display: "inline-flex",
            padding: "8px 18px", // medium
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "12px",
            border: "1px solid var(--Neutral-300, #D5D7DA)",
            backgroundColor: "#FFF",
            color: "var(--Willow-Green-600, #0C4F42)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px", // medium
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "none",
            lineHeight: "normal",
            "&:hover": {
              backgroundColor: "var(--Willow-Green-25, #E5F7F4)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#FFF",
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #3C81F1",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--Neutral-100, #F5F5F5)",
              color: "var(--Neutral-500, #717680)",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary", size: "large" },
          style: {
            display: "inline-flex",
            padding: "8px 20px", // large
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "12px",
            border: "1px solid var(--Neutral-300, #D5D7DA)",
            backgroundColor: "#FFF",
            color: "var(--Willow-Green-600, #0C4F42)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "20px", // large
            fontStyle: "normal",
            fontWeight: 500,
            textTransform: "none",
            lineHeight: "normal",
            "&:hover": {
              backgroundColor: "var(--Willow-Green-25, #E5F7F4)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#FFF",
              boxShadow: "0px 0px 0px 2px #FFF, 0px 0px 0px 4px #3C81F1",
            },
            "&.Mui-disabled": {
              backgroundColor: "var(--Neutral-100, #F5F5F5)",
              color: "var(--Neutral-500, #717680)",
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-sizeSmall": {
            padding: "2px 8px",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "18px",
          },
          "&.MuiChip-sizeMedium": {
            padding: "4px 12px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
          },
          "&.MuiChip-sizeLarge": {
            padding: "6px 16px",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
          },
        },
        label: {
          padding: 0,
        },
      },
      defaultProps: {
        size: "small",
      },
    },
  },
});
