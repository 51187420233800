import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { useRecoilValue } from "recoil";
import RecommendationPreferencesForm from "./RecommendationPreferencesForm";
import RecommendationPreferencesLayout from "./RecommendationPreferencesLayout";

function RecommendationPreferencesContainer() {
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  return (
    <>
      <RecommendationPreferencesLayout>
        {loggedInStudent && <RecommendationPreferencesForm student={loggedInStudent} />}
      </RecommendationPreferencesLayout>
    </>
  );
}

export default RecommendationPreferencesContainer;
