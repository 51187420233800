import React from "react";
import { Box, AppBar, Toolbar, Grid, Button } from "@mui/material";
import { ReactComponent as Logo } from "../../../shared/assets/branding/willow-bare-icon.svg";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import useLogout from "../../../shared/hooks/auth/useLogout";
import { challenges } from "../../../shared/assets/data/pressingChallenges";

type Props = {
  currentStep: number;
  children: React.ReactNode;
  handleContinue?: () => void;
  handleBack?: () => void;
  disableContinue?: boolean;
};

const PressingChallengesLayout = ({ children, currentStep, handleContinue, handleBack, disableContinue }: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const { logout } = useLogout();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
        bgcolor: "#f5fdf9",
        px: 2,
        pb: 4,
      }}
    >
      {/* Header */}
      <Box>
        <AppBar
          position="sticky"
          sx={{
            bgcolor: "transparent",
            boxShadow: "none",
            py: 2,
          }}
        >
          <Toolbar
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ height: 48, width: 48 }}>
              <Logo style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box>
              <Button variant="text" sx={{ color: "#333" }} onClick={logout}>
                Log Out
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Content Area */}
        <Grid container justifyContent="center" sx={{ mt: 0 }}>
          <Grid item xs={12} sm={1} md={2} lg={3}></Grid>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Box
              sx={{
                bgcolor: "#ffffff",
                borderRadius: "24px",
                boxShadow: "0px 2px 8px -1px rgba(16, 24, 40, 0.08), 0px 2px 8px -1px rgba(16, 24, 40, 0.08)",
                px: 4,
                pb: 1,
                pt: 1,
                height: "80vh",
                overflowY: "auto",
              }}
            >
              {children}
            </Box>
          </Grid>
          <Grid item xs={12} sm={1} md={2} lg={3}></Grid>
        </Grid>
      </Box>

      {/* Progress and Navigation Buttons */}
      {currentStep <= 8 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: mobile ? "column" : "row",
            justifyContent: "center",
            alignItems: "center",
            gap: mobile ? 2 : 1,
            mt: 4,
            mb: mobile ? 4 : 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 1,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {Array.from({ length: 8 }, (_, i) => i + 1).map((stepNum) => (
              <Box
                key={stepNum}
                sx={{
                  width: mobile ? 26 : 56,
                  height: 8,
                  borderRadius: "4px",
                  bgcolor: stepNum <= currentStep ? "#10b981" : "#d1d5db",
                }}
              />
            ))}
          </Box>
          {currentStep > 1 && handleBack && (
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{
                color: "#10b981",
                borderColor: "#10b981",
                px: 2,
                py: 1,
                borderRadius: 3,
                mt: mobile ? 2 : 0,
                ml: 2,
              }}
            >
              Back
            </Button>
          )}
          {handleContinue && (
            <Button
              variant="contained"
              onClick={handleContinue}
              disabled={disableContinue}
              sx={{
                color: "#fff",
                bgcolor: "#10b981",
                px: 2,
                py: 1,
                borderRadius: 3,
                mt: mobile ? 2 : 0,
              }}
            >
              {currentStep < 8 ? "Continue" : "Submit"}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default PressingChallengesLayout;
