import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import OnboardingLayout from "./OnboardingLayout";
import { useOnboarding } from "../../hooks/onboarding/useOnboarding";
import { useNavigate } from "react-router-dom";
import { PageRoute } from "../../../shared/types/enums";
import { useRecoilState } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";

const MyWhyStep: React.FC = () => {
  const [myWhy, setMyWhy] = useState("");
  const [error, setError] = useState(false);

  const { updateStudentData } = useOnboarding();
  const navigate = useNavigate();
  const [loggedInStudent, setLoggedInStudent] = useRecoilState(loggedInStudentAtom);

  // Populate stored values on component load
  React.useEffect(() => {
    if (loggedInStudent?.myWhy) {
      setMyWhy(loggedInStudent.myWhy);
    }
  }, [loggedInStudent]);

  const handleBlur = () => {
    setError(myWhy.trim() === "");
  };

  const handleContinue = async () => {
    if (!myWhy.trim()) {
      setError(true);
      return;
    }

    try {
      await updateStudentData({ myWhy, onboardingStage: 5 });
      navigate(PageRoute.ONBOARDING_FEEDBACK);
    } catch (error) {
      console.error("Error updating myWhy:", error);
    }
  };

  const handleBack = () => {
    setLoggedInStudent((prev) => (prev !== null ? { ...prev, onboardingStage: 3 } : null));
    navigate(PageRoute.ONBOARDING_SCHOOL_INFO);
  };

  return (
    <OnboardingLayout currentStep={4} handleContinue={handleContinue} handleBack={handleBack}>
      <Box sx={{ textAlign: "left", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            mx: "auto",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            {loggedInStudent?.firstName}, why is it important to you to make a great next step after high school?
          </Typography>
          <Typography sx={{ mt: 1, color: "text.secondary" }}>
            For example, to find a great career, to make a difference in the world, to support your family, etc.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            maxWidth: 500,
            mx: "auto",
          }}
        >
          <TextField
            label="Your Why"
            placeholder="Explain why this matters..."
            multiline
            rows={6}
            variant="outlined"
            fullWidth
            value={myWhy}
            onChange={(e) => setMyWhy(e.target.value)}
            onBlur={handleBlur}
            error={error}
            helperText={error ? "This field is required" : ""}
          />
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default MyWhyStep;
