import React from "react";
import { Box, AppBar, Toolbar, Grid, Button, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../../shared/assets/branding/willow-light-bare-icon.svg";
import useWindowDimensions from "../../../shared/hooks/responsiveLayout/useWindowDimensions";
import useLogout from "../../../shared/hooks/auth/useLogout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type Props = {
  children: React.ReactNode;
  onNext?: () => void;
  onPrevious?: () => void;
  showPrevious?: boolean;
  isLastQuestion?: boolean;
  isStartPage?: boolean;
  isPreviewPage?: boolean;
};

const PersonalityQuizLayout = ({
  children,
  onNext,
  onPrevious,
  showPrevious,
  isLastQuestion,
  isStartPage,
  isPreviewPage,
}: Props) => {
  const { width } = useWindowDimensions();
  const mobile = width < 900;
  const { logout } = useLogout();

  const sideWidth = { xs: 24, sm: 4, md: 4, lg: 6 };
  const centerWidth = { xs: 24, sm: 16, md: 16, lg: 12 };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#00362E",
        px: 2,
        pb: 1,
        overflowY: "scroll",
      }}
    >
      <AppBar position="static" sx={{ bgcolor: "transparent", boxShadow: "none", pt: 2 }}>
        <Toolbar sx={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
          <Box sx={{ height: 48, width: 48 }}>
            <Logo style={{ height: "100%", width: "100%" }} />
          </Box>
          <Typography sx={{ color: "rgba(255, 255, 255, .7)", fontSize: 24 }}>Willow's Personality Quiz</Typography>
          <Button variant="text" sx={{ color: "#E5F7F4" }} onClick={logout}>
            Log Out
          </Button>
        </Toolbar>
      </AppBar>

      <Box>
        <Grid container justifyContent="center" sx={{ mt: 0 }} columns={24}>
          <Grid item xs={24} sm={isPreviewPage ? 2 : 4} md={isPreviewPage ? 4 : 5} lg={isPreviewPage ? 4 : 6}>
            {showPrevious && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "calc(100vh - 72px)",
                }}
              >
                <Button
                  variant="contained"
                  onClick={onPrevious}
                  sx={{
                    px: 4,
                    py: 1,
                    backgroundColor: "rgba(229, 247, 244, 0.30)",
                    borderRadius: 3,
                    ml: 4,
                    textTransform: "none",
                  }}
                  startIcon={<ArrowBackIcon />}
                >
                  Back
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={24} sm={isPreviewPage ? 20 : 16} md={isPreviewPage ? 16 : 14} lg={isPreviewPage ? 16 : 12}>
            {children}
          </Grid>
          <Grid item xs={24} sm={isPreviewPage ? 2 : 4} md={isPreviewPage ? 4 : 5} lg={isPreviewPage ? 4 : 6}>
            {!isStartPage && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: "calc(100vh - 72px)",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    px: 4,
                    py: 1,
                    backgroundColor: "rgba(229, 247, 244, 0.30)",
                    borderRadius: 3,
                    mr: 4,
                    textTransform: "none",
                  }}
                  endIcon={<ArrowForwardIcon />}
                  onClick={onNext}
                >
                  {isLastQuestion ? "Submit" : "Next"}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PersonalityQuizLayout;
