import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, MenuItem, Select, InputLabel, FormControl, FormHelperText } from "@mui/material";
import OnboardingLayout from "./OnboardingLayout";
import { useOnboarding } from "../../hooks/onboarding/useOnboarding";
import { GradeLevel, PageRoute } from "../../../shared/types/enums";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { schoolsAtom } from "../../../shared/recoil/schoolAndDistrictAtoms";
import { useNavigate } from "react-router-dom";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";

const SchoolInfoStep: React.FC = () => {
  const [school, setSchool] = useState("");
  const [gradeLevel, setGradeLevel] = useState("");
  const [externalId, setExternalId] = useState("");

  const [errors, setErrors] = useState({
    school: false,
    gradeLevel: false,
  });

  const { updateStudentData } = useOnboarding();
  const gradeLevels: GradeLevel[] = Object.values(GradeLevel).map((value) => value);
  const schools = useRecoilValue(schoolsAtom);
  const navigate = useNavigate();
  const setLoggedInStudent = useSetRecoilState(loggedInStudentAtom);
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);

  useEffect(() => {
    if (loggedInStudent) {
      setSchool(loggedInStudent.schoolId || "");
      setGradeLevel(loggedInStudent.gradeLevel || "");
      setExternalId(loggedInStudent.externalId || "");
    }
  }, [loggedInStudent]);

  const handleBlur = (field: keyof typeof errors) => {
    const fieldValues = {
      school,
      gradeLevel,
    };

    setErrors((prev) => ({ ...prev, [field]: !fieldValues[field] }));
  };

  const handleContinue = async () => {
    if (!school || !gradeLevel) {
      setErrors({
        school: !school,
        gradeLevel: !gradeLevel,
      });
      return;
    }

    try {
      const districtId = schools.find((s) => s.id === school)?.districtId;
      await updateStudentData({
        schoolId: school,
        gradeLevel,
        externalId,
        onboardingStage: 4,
        districtId,
      });
      // Navigate to the next step
    } catch (error) {
      console.error("Error updating school info:", error);
    }
  };

  const handleBack = () => {
    setLoggedInStudent((prev) => (prev !== null ? { ...prev, onboardingStage: 2 } : null));
    navigate(PageRoute.ONBOARDING_PERSONAL_INFO);
  };

  return (
    <OnboardingLayout currentStep={3} handleContinue={handleContinue} handleBack={handleBack}>
      <Box sx={{ textAlign: "left", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
            mx: "auto",
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Tell us about your school
          </Typography>
          <Typography sx={{ mt: 1, color: "text.secondary" }}>Provide details about your school to continue</Typography>
        </Box>
        <Box
          sx={{
            mt: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            maxWidth: 500,
            mx: "auto",
          }}
        >
          <FormControl fullWidth error={errors.school}>
            <InputLabel id="school-select-label">Select School</InputLabel>
            <Select
              labelId="school-select-label"
              value={school}
              onChange={(e) => setSchool(e.target.value)}
              onBlur={() => handleBlur("school")}
              label="Select School"
              variant="outlined"
            >
              <MenuItem value="">Select School</MenuItem>
              {schools.map((school) => (
                <MenuItem key={school.id} value={school.id}>
                  {school.name}
                </MenuItem>
              ))}
            </Select>
            {errors.school && <FormHelperText>School is required</FormHelperText>}
          </FormControl>
          <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
            <FormControl fullWidth error={errors.gradeLevel}>
              <InputLabel id="grade-level-select-label">Select Grade Level</InputLabel>
              <Select
                labelId="grade-level-select-label"
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
                onBlur={() => handleBlur("gradeLevel")}
                label="Select Grade Level"
                variant="outlined"
              >
                <MenuItem value="">Select Grade Level</MenuItem>
                {gradeLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
              {errors.gradeLevel && <FormHelperText>Grade level is required</FormHelperText>}
            </FormControl>
            <TextField
              label="School ID (optional)"
              variant="outlined"
              fullWidth
              value={externalId}
              onChange={(e) => setExternalId(e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default SchoolInfoStep;
