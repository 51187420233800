import React from "react";
import { Box, AppBar, Toolbar, Grid, Button } from "@mui/material";
import { ReactComponent as Logo } from "../../../shared/assets/branding/willow-bare-icon.svg";
import useLogout from "../../../shared/hooks/auth/useLogout";

type Props = {
  children: React.ReactNode;
};

const RecommendationPreferencesLayout = ({ children }: Props) => {
  const { logout } = useLogout();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
        bgcolor: "#f5fdf9",
        px: 2,
        pb: 4,
      }}
    >
      {/* Header */}
      <Box>
        <AppBar
          position="sticky"
          sx={{
            bgcolor: "transparent",
            boxShadow: "none",
            py: 2,
          }}
        >
          <Toolbar
            sx={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ height: 48, width: 48 }}>
              <Logo style={{ height: "100%", width: "100%" }} />
            </Box>
            <Box>
              <Button variant="text" sx={{ color: "#333" }} onClick={logout}>
                Log Out
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        {/* Content Area */}
        <Grid container justifyContent="center" sx={{ mt: 0 }}>
          <Grid item xs={12} sm={1} md={2} lg={3}></Grid>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Box
              sx={{
                bgcolor: "#ffffff",
                borderRadius: "24px",
                boxShadow: "0px 2px 8px -1px rgba(16, 24, 40, 0.08), 0px 2px 8px -1px rgba(16, 24, 40, 0.08)",
                px: 4,
                pb: 1,
                pt: 1,
              }}
            >
              {children}
            </Box>
          </Grid>
          <Grid item xs={12} sm={1} md={2} lg={3}></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RecommendationPreferencesLayout;
