import React, { useCallback, useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useOnboarding } from "../../hooks/onboarding/useOnboarding";
import OnboardingLayout from "./OnboardingLayout";
import { Autocomplete } from "@react-google-maps/api";
import { formatPhoneNumber } from "../../../shared/utils/formatUtils";
import { useRecoilValue } from "recoil";
import { loggedInStudentAtom } from "../../../shared/recoil/userAtoms";
import { AddressComponents } from "../../../shared/types/types";

const PersonalInfoStep: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState<string | null>(null);
  const [displayBirthday, setDisplayBirthday] = useState<string>("");
  const [address, setAddress] = useState<AddressComponents>({
    address: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    lat: 0,
    lon: 0,
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    birthday: false,
    address: false,
    phoneNumber: false,
  });

  const { updateStudentData } = useOnboarding();
  const loggedInStudent = useRecoilValue(loggedInStudentAtom);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    if (loggedInStudent) {
      setFirstName(loggedInStudent.firstName || "");
      setLastName(loggedInStudent.lastName || "");

      if (loggedInStudent.birthday) {
        const formattedDate = new Date(loggedInStudent.birthday).toISOString().split("T")[0];
        setBirthday(loggedInStudent.birthday);
        setDisplayBirthday(formattedDate);
      }

      setAddress({
        address: loggedInStudent.address?.address || "",
        city: loggedInStudent.address?.city || "",
        state: loggedInStudent.address?.state || "",
        zip: loggedInStudent.address?.zip || "",
        county: loggedInStudent.address?.county || "",
        lat: loggedInStudent.address?.lat || 0,
        lon: loggedInStudent.address?.lon || 0,
      });

      if (loggedInStudent.phone) {
        const cleanedValue = loggedInStudent.phone.replace(/\D/g, "") ?? "";
        const formattedValue = formatPhoneNumber({ phoneNumber: cleanedValue });
        setPhoneNumber(formattedValue || "");
      }
    }
  }, [loggedInStudent]);

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        setAddress((prev) => ({
          ...prev,
          address: place.formatted_address || "",
        }));
      }
    }
  };

  const handleBlur = (field: keyof typeof errors) => {
    const fieldValues = {
      firstName,
      lastName,
      birthday,
      address: address.address, // Checking if the address string is filled
      phoneNumber,
    };

    setErrors((prev) => ({ ...prev, [field]: !fieldValues[field] }));
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    }
    setErrors((prev) => ({ ...prev, [name]: value === "" }));
  };

  const handleBirthdayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    setDisplayBirthday(dateValue);
    try {
      const isoString = new Date(dateValue).toISOString();
      setBirthday(isoString);
      setErrors((prev) => ({ ...prev, birthday: false }));
    } catch (error) {
      console.error("Invalid date format:", error);
      setBirthday(null);
      setErrors((prev) => ({ ...prev, birthday: true }));
    }
  };

  const handlePhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const cleanedValue = value.replace(/\D/g, "");
    const formattedValue = formatPhoneNumber({ phoneNumber: cleanedValue });
    setPhoneNumber(formattedValue);
    setErrors((prev) => ({ ...prev, phoneNumber: formattedValue.length !== 16 }));
  }, []);

  const handleContinue = async () => {
    if (!firstName || !lastName || !birthday || !address.address || phoneNumber.length !== 16) {
      setErrors({
        firstName: !firstName,
        lastName: !lastName,
        birthday: !birthday,
        address: !address.address,
        phoneNumber: phoneNumber.length !== 16,
      });
      return;
    }

    try {
      await updateStudentData({
        firstName,
        lastName,
        birthday,
        address,
        phone: phoneNumber,
        onboardingStage: 3,
      });
    } catch (error) {
      console.error("Error updating student data:", error);
    }
  };

  return (
    <OnboardingLayout currentStep={2} handleContinue={handleContinue}>
      <Box sx={{ textAlign: "left", mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: 500, mx: "auto" }}>
          <Typography variant="h4" fontWeight="bold">
            {`A little bit about you, ${firstName}`}
          </Typography>
          <Typography sx={{ mt: 1, color: "text.secondary" }}>Fill in your personal details to continue</Typography>
        </Box>
        <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 2, width: "100%", maxWidth: 500, mx: "auto" }}>
          <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", sm: "row" } }}>
            <TextField
              label="First Name"
              variant="outlined"
              name="firstName"
              fullWidth
              value={firstName}
              onChange={handleTextChange}
              onBlur={() => handleBlur("firstName")}
              error={errors.firstName}
              helperText={errors.firstName ? "First Name is required" : ""}
            />
            <TextField
              label="Last Name"
              name="lastName"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={handleTextChange}
              onBlur={() => handleBlur("lastName")}
              error={errors.lastName}
              helperText={errors.lastName ? "Last Name is required" : ""}
            />
          </Box>
          <TextField
            label="Birthday"
            type="date"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={displayBirthday}
            onChange={handleBirthdayChange}
            onBlur={() => handleBlur("birthday")}
            error={errors.birthday}
            helperText={errors.birthday ? "Birthday is required" : ""}
          />
          <Autocomplete onLoad={(instance) => setAutocomplete(instance)} onPlaceChanged={handlePlaceChanged}>
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={address.address}
              onChange={(e) => setAddress({ ...address, address: e.target.value })}
              onBlur={() => handleBlur("address")}
              error={errors.address}
              helperText={errors.address ? "Address is required" : ""}
            />
          </Autocomplete>
          <TextField
            label="Phone"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={handlePhoneChange}
            onBlur={() => handleBlur("phoneNumber")}
            inputProps={{ maxLength: 16 }}
            error={errors.phoneNumber}
            helperText={errors.phoneNumber ? "Phone number is required" : ""}
          />
        </Box>
      </Box>
    </OnboardingLayout>
  );
};

export default PersonalInfoStep;
