import { atom, selector } from "recoil";
import { DistrictRecord, SchoolRecord } from "../types/types";

export const allSchoolsAtom = atom<SchoolRecord[]>({
  key: "allSchoolsAtom",
  default: [],
});

export const schoolsAtom = selector<SchoolRecord[]>({
  key: "schoolsAtom",
  get: ({ get }) => {
    const schools = get(allSchoolsAtom);
    const selectedDistrict = get(selectedDistrictAtom);
    console.log("selectedDistrict", selectedDistrict);
    if (selectedDistrict) {
      return schools.filter((school) => school.districtId === selectedDistrict.id);
    }
    return schools;
  },
});

export const districtsAtom = atom<DistrictRecord[]>({
  key: "districtsAtom",
  default: [],
});

export const selectedSchoolAtom = atom<SchoolRecord | null>({
  key: "selectedSchoolAtom",
  default: null,
});

export const selectedDistrictAtom = atom<DistrictRecord | null>({
  key: "selectedDistrictAtom",
  default: null,
});
