import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import FormikAddress from "../../../shared/components/googleAddress/FormikAddress";
import { StudentRecord } from "../../../shared/types/types";
import LoadingBlock from "../../../shared/components/loading/LoadingBlock";
import { AddressType, FRLStatus } from "../../../shared/types/enums";
import StateSelect from "./StateSelect";
import cities from "../../../shared/assets/data/cities.json";
import EFCInput from "./EFCInput";
import CitySelect from "./CitySelect";
import useRecommendationPreferences from "../../hooks/recommendationPreferences/useRecommendationPreferences";

type Props = {
  student: StudentRecord;
};

function RecommendationPreferencesForm({ student }: Props) {
  const {
    initialValues,
    unlockValidationSchema,
    listOfCities,
    finanicalData,
    setListOfCities,
    setFinanicalData,
    handleSubmit,
  } = useRecommendationPreferences({
    cities,
    student,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={unlockValidationSchema}
          onSubmit={handleSubmit}
          validateOnMount={true}
        >
          {({ errors, touched, isSubmitting, isValid, values }) => (
            <>
              {isSubmitting ? (
                <LoadingBlock size={100} mt={8} />
              ) : (
                <Form style={{ width: "100%" }} autoComplete="off">
                  <Box
                    sx={{
                      width: "100%",
                      padding: "16px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Grid container spacing={2} columns={14}>
                      <Grid item xs={14} sm={7}>
                        <FormControl fullWidth margin="normal" sx={{ pt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor="programTypeForRecommendations"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-5px",
                            }}
                          >
                            Which Best Describes You?
                          </InputLabel>
                          <Field
                            as={Select}
                            id="programTypeForRecommendations"
                            name="programTypeForRecommendations"
                            fullWidth
                            sx={{ maxWidth: "100%" }}
                            error={
                              touched.programTypeForRecommendations && Boolean(errors.programTypeForRecommendations)
                            }
                          >
                            <MenuItem value={"college"}>
                              I'm mainly interested in college programs after I graduate
                            </MenuItem>
                            <MenuItem value={"professional"}>
                              I'm mainly interested in non-college pathways like trade schools after I graduate
                            </MenuItem>
                            <MenuItem value={"mix"}>
                              I'm interested in both college and non-college pathways after I graduate
                            </MenuItem>
                          </Field>
                          {touched.programTypeForRecommendations && Boolean(errors.programTypeForRecommendations) ? (
                            <FormHelperText error>{errors.programTypeForRecommendations}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item xs={14} sm={3}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel
                            shrink
                            htmlFor="gpaValue"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-5px",
                            }}
                          >
                            GPA
                          </InputLabel>
                          <Field
                            as={TextField}
                            id="gpaValue"
                            name="gpaValue"
                            required
                            type="number"
                            margin="normal"
                            inputProps={{ step: "any" }}
                            sx={{ width: "100%" }}
                            error={touched.gpaValue && Boolean(errors.gpaValue)}
                            helperText={touched.gpaValue && errors.gpaValue}
                            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                              if (e.key === "e" || e.key === "E") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={14} sm={1}>
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            mt: touched.gpaValue && errors.gpaValue ? -1 : 1,
                          }}
                        >
                          Out Of
                        </Typography>
                      </Grid>
                      <Grid item xs={14} sm={3}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel
                            shrink
                            htmlFor="gpaMax"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-5px",
                            }}
                          >
                            Max Possible Points
                          </InputLabel>
                          <Field
                            as={TextField}
                            id="gpaMax"
                            name="gpaMax"
                            required
                            margin="normal"
                            type="number"
                            inputProps={{ step: "any" }}
                            sx={{ width: "100%" }}
                            error={touched.gpaMax && Boolean(errors.gpaMax)}
                            helperText={touched.gpaMax && errors.gpaMax}
                            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                              if (e.key === "e" || e.key === "E") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal" sx={{ pt: 2 }}>
                          <InputLabel
                            shrink
                            htmlFor="frlStatus"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-5px",
                            }}
                          >
                            Free/Reduced Lunch Status
                          </InputLabel>
                          <Field
                            as={Select}
                            id="frlStatus"
                            name="frlStatus"
                            fullWidth
                            sx={{ maxWidth: "100%" }}
                            error={touched.frlStatus && Boolean(errors.frlStatus)}
                          >
                            <MenuItem
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              value={FRLStatus.ReceivesFreeOrReducedMeals}
                            >
                              {FRLStatus.ReceivesFreeOrReducedMeals}
                            </MenuItem>
                            <MenuItem value={FRLStatus.DoesNotReceiveFreeOrReducedMeals}>
                              {FRLStatus.DoesNotReceiveFreeOrReducedMeals}
                            </MenuItem>
                            <MenuItem value={FRLStatus.UnknownOrDoesNotAttendPublicSchool}>
                              {FRLStatus.UnknownOrDoesNotAttendPublicSchool}
                            </MenuItem>
                          </Field>
                          {touched.frlStatus && Boolean(errors.frlStatus) ? (
                            <FormHelperText error>{errors.frlStatus}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel
                            shrink
                            htmlFor="act"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-5px",
                            }}
                          >
                            ACT (Optional)
                          </InputLabel>
                          <Field
                            as={TextField}
                            id="act"
                            name="act"
                            fullWidth
                            margin="normal"
                            type="number"
                            error={touched.act && Boolean(errors.act)}
                            helperText={touched.act && errors.act}
                            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                              if (e.key === "e" || e.key === "E") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel
                            shrink
                            htmlFor="sat"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-5px",
                            }}
                          >
                            SAT (Optional)
                          </InputLabel>
                          <Field
                            as={TextField}
                            id="sat"
                            name="sat"
                            fullWidth
                            margin="normal"
                            type="number"
                            error={touched.sat && Boolean(errors.sat)}
                            helperText={touched.sat && errors.sat}
                            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                              if (e.key === "e" || e.key === "E") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EFCInput
                          errors={errors}
                          touched={touched}
                          finanicalData={finanicalData}
                          setFinanicalData={setFinanicalData}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel
                            shrink
                            htmlFor="includeOnlineOnly"
                            variant="standard"
                            sx={{
                              display: "flex",
                              fontSize: 16,
                              fontWeight: 600,
                              top: "-20px",
                            }}
                          >
                            Interested in Online Only Programs?
                          </InputLabel>
                          <Field
                            as={Select}
                            id="includeOnlineOnly"
                            name="includeOnlineOnly"
                            fullWidth
                            sx={{ maxWidth: "100%" }}
                          >
                            <MenuItem value={"true"}>Yes</MenuItem>
                            <MenuItem value={"false"}>No</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <StateSelect setListOfCities={setListOfCities} name="statesForRecommendations" />
                      </Grid>
                      {values.statesForRecommendations.length > 0 && (
                        <Grid item xs={12} sm={6}>
                          <CitySelect listOfCities={listOfCities} name="citiesForRecommendations" />
                        </Grid>
                      )}
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting || !isValid}
                      sx={{ mt: 2, flexGrow: 3 }}
                    >
                      {"Submit and Let's Get Started!"}
                    </Button>
                  </Box>
                </Form>
              )}
            </>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default RecommendationPreferencesForm;
